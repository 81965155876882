import { Container, Row, Col, Button } from 'react-bootstrap';

import Lock from '../../svg/Lock.svg';
import Clock from '../../svg/Clock.svg';
import Reveller from '../../svg/Reveller.svg';
import Tiers from '../../svg/Tiers.svg';
import Increase from '../../svg/Increase.svg';

const Builder3 = ({
  generatePlanHandler,
  backToCreatePlan,
  calculatedGains,
  disableFinishButton,
  disableEditButton,
}) => {
  const startHandler = () => {
    generatePlanHandler();
  };

  const backHandler = () => {
    backToCreatePlan();
  };

  return (
    <div className="builder builder3">
      <Container>
        <Row>
          <Col>
            <div className="title">REWARD PLAN SUMMARY</div>
          </Col>
        </Row>
        <Row className="summary-icons">
          <Col
            xs={6}
            sm={3}
          >
            <div className="icon">
              <img
                src={Lock}
                alt="Lock icon"
              />
            </div>
            <div className="title">Locked</div>
            <div className="value">
              {calculatedGains.bpmAmount.toLocaleString('en', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </Col>
          <Col
            xs={6}
            sm={3}
          >
            <div className="icon">
              <img
                src={Clock}
                alt="Clock icon"
              />
            </div>
            <div className="title">Period</div>
            <div className="value">{calculatedGains.period} months</div>
          </Col>
          <Col
            xs={6}
            sm={3}
          >
            <div className="icon">
              <img
                src={Tiers}
                alt="Tier icon"
              />
            </div>
            <div className="title">Qty</div>
            <div className="value">Level {calculatedGains.bpmTier}</div>
          </Col>
          <Col
            xs={6}
            sm={3}
          >
            <div className="icon">
              <img
                src={Reveller}
                alt="Reveller icon"
              />
            </div>
            <div className="title">Reveller</div>
            <div className="value">{calculatedGains.revellerTier}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="projected-rewards">PROJECTED REWARDS</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="icon">
              <img
                src={Increase}
                alt="Increase icon"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="projected-earnings">
              {calculatedGains.projectedEarningsBpm.toLocaleString('en', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              BPM
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="projected-percentage">+ {calculatedGains.percentageGain}%</div>
          </Col>
        </Row>
        <Row className="action-buttons">
          <Col sm={6}>
            <Button
              onClick={backHandler}
              variant="outline-primary"
              disabled={disableEditButton}
            >
              Edit plan
            </Button>
          </Col>
          <Col sm={6}>
            <Button
              onClick={startHandler}
              variant="primary"
              disabled={disableFinishButton}
              className="start-earning"
            >
              <span>Start earning</span>
              {disableFinishButton && <span className="spinner btn-spinner"></span>}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Builder3;
