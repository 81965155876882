import { Container, Row, Col } from 'react-bootstrap';

import Warning from '../../svg/Warning.svg';

const Ineligible = () => {
  return (
    <Container className="ineligible">
      <Row>
        <Col>
          <div className="icon">
            <img
              src={Warning}
              alt="Warning icon"
            ></img>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="title">INSUFFICIENT BALANCE</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="supporting-text">
            You do not have enough unallocated BPM left to create or modify a plan.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="action-buttons">
            <a
              role="button"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
              href="https://bpmwallet.io/buy-bpm-tokens"
            >
              Buy BPM
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Ineligible;
