import { useContext } from 'react';

import axios from '../api/axios';
import AuthContext from '../context/AuthProvider';
import useError from './useError';

const useLogout = () => {
  const errorHandler = useError();
  const { setUser, setToken, setPersist, setRoles } = useContext(AuthContext);

  const logout = async () => {
    try {
      await axios('/account/logout', { withCredentials: true });

      setUser('');
      setToken('');
      setRoles([]);
      setPersist(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  return logout;
};

export default useLogout;
