import { Container, Row, Col } from "react-bootstrap";

const Missing = () => {
  return (
    <section className="hero">
      <Container className="home-hero">
        <Row>
          <Col>
            <h1 className="text-white-outline">Oops</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Missing;
