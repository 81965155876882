import { useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";

import Slider from "rc-slider";

import PieChart from "../../svg/PieChart.svg";
import Calendar from "../../svg/Calendar.svg";

const Builder2 = ({ planCreatedHandler, planSetup, currentPlan }) => {
  // Refs
  const amountRef = useRef();
  const periodRef = useRef();

  // State
  const [planAmountPercentage, setPlanAmountPercentage] = useState(
    40000000 < planSetup.balanceBpm ? 20000000 : planSetup.balanceBpm / 2
  );
  const [planAmount, setPlanAmount] = useState(planAmountPercentage);
  const [generating, setGenerating] = useState(false);

  // Handlers
  const amountChangeHandler = (val) => {
    setPlanAmount(val);
    setPlanAmountPercentage(+val);
  };

  const generatePlanHandler = () => {
    setGenerating(true);
    const amount = planAmountPercentage;
    planCreatedHandler(
      amount,
      periodRef.current.value,
      ((planAmountPercentage / planSetup.balanceBpm) * 100).toLocaleString(
        "en",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )
    );
  };

  return (
    <div className="builder builder2">
      <div className="title">BUILD YOUR PLAN</div>
      <div className="icon">
        <img src={PieChart} alt="Amount icon" />
      </div>
      <div className="question">HOW MUCH DO YOU WANT TO LOCK-UP?</div>
      <div className="rc-slider-container">
        <Slider
          defaultValue={planAmountPercentage}
          onChange={amountChangeHandler}
          ref={amountRef}
          min={0}
          max={
            planSetup.balanceBpm > 20000000 ? 20000000 : planSetup.balanceBpm
          }
          value={planAmountPercentage}
        />
      </div>
      <div className="plan-amount-percentage">
        {((planAmountPercentage / planSetup.balanceBpm) * 100).toLocaleString(
          "en",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        )}
        %
      </div>
      <div className="of-holdings">of my holdings</div>
      <div className="plan-amount">
        {Number(parseFloat(planAmount).toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })}
        <span className="currency">BPM</span>
      </div>
      <img src={Calendar} alt="Period icon" />
      <div className="question">FOR HOW MANY MONTHS?</div>
      <div className="period-selector-container">
        <Form.Select
          aria-label="Period selector"
          ref={periodRef}
          defaultValue={
            currentPlan?.lockupPeriodMonth ? currentPlan?.lockupPeriodMonth : 12
          }
          className="period-selector"
        >
          {planSetup.months.map((period) => (
            <option value={period} key={period}>
              {period}
            </option>
          ))}
        </Form.Select>
      </div>
      <Button onClick={generatePlanHandler} disabled={generating}>
        Generate plan{generating && <div className="spinner btn-spinner"></div>}
      </Button>
    </div>
  );
};

export default Builder2;
