import { Container, Row, Col } from 'react-bootstrap';

import Increase from '../../svg/Increase.svg';
import Calendar from '../../svg/Calendar.svg';
import Money from '../../svg/Money.svg';
import Scales from '../../svg/Scales.svg';
import Graph from '../../svg/Graph.svg';

const History = ({ payments, balanceHistory }) => {
  return (
    <section className="table-widget-container">
      <Container className="table-widget">
        <Row>
          <Col lg={6}>
            <h3>
              <span className="text-white-outline">Pending</span>{' '}
              <span className="text-white-bold">Rewards</span>
            </h3>
            <p>Keep track of your rewards during your lock-up period.</p>
            <Container className="table-head">
              <Row>
                <Col xs={4}>
                  <img
                    src={Calendar}
                    alt="Calendar icon"
                  />
                  <span>DATE</span>
                </Col>
                <Col xs={4}>
                  <img
                    src={Money}
                    alt="Amount icon"
                  />

                  <span>REWARD</span>
                </Col>
                <Col xs={4}>
                  <img
                    src={Increase}
                    alt="Gain icon"
                  />

                  <span>GAIN</span>
                </Col>
              </Row>
            </Container>

            <Container className="table-data">
              {payments && payments.length > 0 ? (
                payments.map((payment, index) => {
                  return (
                    <Row key={index}>
                      <Col xs={4}>{new Date(payment.date).toLocaleDateString('en-GB')}</Col>
                      <Col xs={4}>
                        {payment.amount.toLocaleString('en', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Col>
                      <Col xs={4}>{payment.percentageGainAsString}</Col>
                    </Row>
                  );
                })
              ) : (
                <Row>
                  <Col>No data</Col>
                </Row>
              )}
            </Container>
          </Col>
          <Col lg={6}>
            <h3>
              <span className="text-white-outline">Balance</span>{' '}
              <span className="text-white-bold">history</span>
            </h3>
            <p>Monitor your $BPM balance changes during your lock-up period.</p>
            <Container className="table-head">
              <Row>
                <Col xs={4}>
                  <img
                    src={Calendar}
                    alt="Calendar icon"
                  />
                  <span>DATE</span>
                </Col>
                <Col xs={4}>
                  <img
                    src={Scales}
                    alt="Balance icon"
                  />

                  <span>BALANCE</span>
                </Col>
                <Col xs={4}>
                  <img
                    src={Graph}
                    alt="Change icon"
                  />
                  <span>CHANGE</span>
                </Col>
              </Row>
            </Container>
            <Container className="table-data">
              {balanceHistory && balanceHistory.length > 0 ? (
                balanceHistory.map((balance, index) => {
                  return (
                    <Row key={index}>
                      <Col xs={4}>{new Date(balance.date).toLocaleDateString('en-GB')}</Col>
                      <Col xs={4}>
                        {balance.balance.toLocaleString('en', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Col>
                      <Col xs={4}>{balance.percentageChangeAsString}</Col>
                    </Row>
                  );
                })
              ) : (
                <Row>
                  <Col>No data</Col>
                </Row>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default History;
