import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteAccount = ({ closeHandler, acceptHandler, rejectHandler, show }) => {
  const [deleteCheck, setDeleteCheck] = useState(false);
  const deleteInputHandler = (e) => {
    if (e.target.value === 'permanently delete my account') {
      setDeleteCheck(true);
    } else {
      setDeleteCheck(false);
    }
  };

  const handleClose = () => {
    closeHandler();
  };
  const handleReject = () => {
    rejectHandler();
  };
  const handleAccept = () => {
    if (deleteCheck) {
      acceptHandler();
    }
  };

  return (
    <>
      <Modal
        className="delete-account-modal"
        show={show}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>Account deletion</Modal.Title>
          <Button
            onClick={handleClose}
            className="btn-close circle"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p>Please type the following in the box below and click delete.</p>
          <p className="keywords">permanently delete my account</p>

          <input
            className="input"
            type="text"
            max="50"
            placeholder="This action cannot be undone!"
            onChange={deleteInputHandler}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleReject}
            size="sm"
            className="me-3"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleAccept}
            size="sm"
            disabled={!deleteCheck}
          >
            Delete Account
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAccount;
