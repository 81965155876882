import { useContext, useMemo } from 'react';
import AuthContext from '../context/AuthProvider';

import { Container, Row, Col } from 'react-bootstrap';

import Revel from '../components/rewards/Revel';
import Summary from '../components/rewards/Summary';
import CreatePlan from '../components/rewards/CreatePlan';
import WalletSelector from '../components/WalletSelector';

const Rewards = () => {
  const { wallets, currentWallet } = useContext(AuthContext);

  const hasWallet = useMemo(() => {
    return wallets?.length > 0;
  }, [wallets]);

  const hasManyWallets = useMemo(() => {
    return wallets?.length > 1;
  }, [wallets]);

  const hasPlan = useMemo(() => {
    const wallet = wallets.find((wallet) => wallet.id === currentWallet);
    const plan = wallet && wallet?.hasRewardAccount;
    return plan;
  }, [wallets, currentWallet]);

  return (
    <>
      {!hasWallet && <Revel />}

      {hasWallet && (
        <>
          <section className="hero-container rewards-hero-container">
            <Container className="hero secondary-hero">
              <Row className="hero-msg">
                <Col>
                  <h1 className="text-white-bold">Rewards</h1>
                </Col>
                <Col>
                  <p>Your rewards, your way. Connect your XUMM Wallet and build your plan.</p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="reward-plan-container">
            {hasManyWallets && (
              <WalletSelector
                title="Select a wallet to view your rewards"
                message="Select a wallet to view and configure your rewards."
              />
            )}
            {hasPlan ? <Summary /> : <CreatePlan />}
          </section>
        </>
      )}
    </>
  );
};

export default Rewards;
