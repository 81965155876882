import { useContext, useEffect, useCallback, useState } from 'react';
import AppContext from '../context/AppProvider';

import { v4 as uuidv4 } from 'uuid';
import ToastType from '../enums/toastType';

const useError = (err) => {
  const { setToast } = useContext(AppContext);
  const [error, setError] = useState(err);

  useEffect(() => {
    if (error === undefined) return;

    if (error?.message === 'canceled') return;
    if (error?.isAxiosError && !error?.response) {
      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.error,
            title: 'Network error',
            message:
              'Looks like you have exceeded the maximum requests per second rule OR the network was unable to process your request.',
          },
        ];
      });
      return;
    }

    if (error && !error?.response) {
      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.error,
            title: 'Server failure',
            message:
              'We are sorry, but looks like there is a problem with our web server please try again later.',
          },
        ];
      });
    } else if (error.response === 'NO_CURRENT_WALLET') {
      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.error,
            title: 'An error occurred',
            message: `You do not have a current wallet selected, if you only have one wallet connected go to your account and set it as teh default wallet, then re-login in.`,
          },
        ];
      });
    } else if (error.response?.status === 498) {
      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.error,
            title: 'Invalid token',
            message: `Automatic login was rejected due to an invalid token, please login again.`,
          },
        ];
      });
    } else if (error.response?.status === 429) {
      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.error,
            title: 'Rate limit',
            message: `You performed too many requests and have been blocked, please try again in a few seconds.`,
          },
        ];
      });
    } else if (error.response?.status === 409) {
      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.warning,
            title: 'Name conflict',
            message: `You cannot have a duplicate wallet alias.`,
          },
        ];
      });
    } else if (error.response) {
      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.error,
            title: 'An error occurred',
            message: `Error ${error.response?.status} was reported by the server, please report this error via the support page.`,
          },
        ];
      });
    }
  }, [error, setToast]);

  const errorHandler = useCallback((err) => {
    setError(err);
  }, []);

  return errorHandler;
};

export default useError;
