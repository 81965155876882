import { useState, useEffect, useContext } from 'react';
import AppContext from '../context/AppProvider';
import AuthContext from '../context/AuthProvider';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useError from '../hooks/useError';

import { v4 as uuidv4 } from 'uuid';
import ToastType from '../enums/toastType';

import { Modal, Row, Col, Button } from 'react-bootstrap';

import Xumm from '../svg/Xumm.svg';
import AppStore from '../svg/AppStore.svg';
import GooglePlay from '../svg/google-play.svg';
import Loading from './Loading';

const defaultMessage = 'Waiting for you to scan the QR code';
const defaultMobileMessage = 'Waiting for you to open XUMM';
const defaultInstructions = 'Open XUMM and scan this QR code, then follow the in-app instructions';
const defaultLoadingMessage = "Requesting 'Sign In' payload from XUMM";
const defaultMobileInstructions =
  'We have detected that you are on a mobile device, please click the button below to open the XUMM App and complete the Sign-In process.';

const ConnectWallet = () => {
  const axiosPrivate = useAxiosPrivate();
  const errorHandler = useError();

  const { setToast, showConnectWallet, setShowConnectWallet, isMobileDevice, setMobileDevice } =
    useContext(AppContext);
  const { setWallets, setRevellers, setCurrentWallet } = useContext(AuthContext);

  // Wallet and xumm state
  const [xummObject, setXummObject] = useState();
  const [qr_expires, setQrExpires] = useState();

  // State messaging
  const [connectionStatus, setConnectionStatus] = useState(
    isMobileDevice ? defaultMobileMessage : defaultMessage
  );
  const [instructions, setInstructions] = useState(
    isMobileDevice ? defaultMobileInstructions : defaultInstructions
  );
  const [loadingMessage, setLoadingMessage] = useState(defaultLoadingMessage);
  const [connectionStatusClass, setConnectionStatusClass] = useState('ok');

  const notMobileDevice = () => {
    setMobileDevice(false);
    setInstructions(defaultInstructions);
  };

  useEffect(() => {
    let socket;
    const syncWallet = async () => {
      const controller = new AbortController();
      try {
        const response = await axiosPrivate.get(`/account/getuser`, {
          signal: controller.signal,
        });

        setConnectionStatus(`Wallet connected! This popup should close automatically`);
        const wallets = response.data?.walletAddresses || [];
        const revellers = response.data?.revellers || [];
        setWallets(wallets);
        setCurrentWallet(wallets.find((wallet) => wallet.default === true)?.id);
        setRevellers(revellers);
        setToast((prev) => {
          return [
            ...prev,
            {
              id: uuidv4(),
              type: ToastType.success,
              title: 'Success',
              message: 'Wallet connected',
            },
          ];
        });
        setShowConnectWallet(false);
        setXummObject(null);
        setQrExpires(null);
        setConnectionStatusClass('ok');
        setConnectionStatus(isMobileDevice ? defaultMobileMessage : defaultMessage);
        setInstructions(defaultInstructions);
      } catch (error) {
        errorHandler(error);
      } finally {
        controller.abort();
      }
    };

    const connectWallet = async () => {
      const controller = new AbortController();
      try {
        const response = await axiosPrivate.get('/xumm/signin', {
          signal: controller.signal,
        });

        setTimeout(() => {
          setXummObject(response.data);

          socket = new WebSocket(response.data.refs.websocket_status);
          socket.onmessage = function (event) {
            const d = JSON.parse(event.data);
            if (d?.expires_in_seconds) {
              const t = new Date(d.expires_in_seconds * 1000).toISOString().slice(11, 19);
              setQrExpires(t);
            }
            if (d?.opened === true) {
              setConnectionStatus("Waiting for you to approve the 'Sign In' request within XUMM");
            }
            if (d?.signed === false) {
              setConnectionStatusClass('fail');
              setInstructions('Close this popup and reload if you want to try again');
              closeWebSocket(socket);
              setConnectionStatus("You declined the 'Sign In' request, wallet connection failed!");
            }
            if (d?.signed === true) {
              closeWebSocket(socket);
              setLoadingMessage("Confirming 'Sign In' with XUMM");
              setXummObject(null);
              setTimeout(async () => {
                await syncWallet();
              }, 2000);
            }
          };
        }, 2000);
      } catch (error) {
        errorHandler(error);
      } finally {
        controller.abort();
      }
    };
    showConnectWallet && connectWallet();

    return () => {
      closeWebSocket(socket);
    };
  }, [
    axiosPrivate,
    errorHandler,
    isMobileDevice,
    setCurrentWallet,
    setRevellers,
    setShowConnectWallet,
    setToast,
    setWallets,
    showConnectWallet,
  ]);

  const closeWebSocket = (ws) => {
    ws?.close();
  };

  const handleConnectWalletClose = () => {
    setShowConnectWallet(false);
    setXummObject(null);
    setQrExpires(null);
    setConnectionStatusClass('ok');
    setConnectionStatus(isMobileDevice ? defaultMobileMessage : defaultMessage);
    setInstructions(defaultInstructions);
  };

  return (
    <>
      <Modal
        show={showConnectWallet}
        size="lg"
        fullscreen="md-down"
        className="xumm-modal"
      >
        <Modal.Header>
          <img
            src={Xumm}
            alt="XUMM logo"
          />
          <Button
            onClick={handleConnectWalletClose}
            className="btn-close circle"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <section>
            <div className="modal-title">Connect your wallet</div>

            {xummObject ? (
              isMobileDevice ? (
                <>
                  <div className="modal-instructions">
                    <p className={`${connectionStatusClass}`}>{instructions}</p>
                  </div>

                  <div className="xumm-mob">
                    <a
                      href={xummObject?.next.always}
                      target="_blank"
                      className="xumm-button"
                      rel="noreferrer"
                    >
                      Open XUMM App on this device
                    </a>
                  </div>

                  <div className="modal-instructions">
                    <p className="not-mobile">
                      If you wish to connect XUMM from a different device,{' '}
                      <span
                        className="change-mobile"
                        onClick={notMobileDevice}
                      >
                        click here
                      </span>{' '}
                      to view a QR code.
                    </p>
                  </div>

                  <div className="expires">
                    <p>Expires in {qr_expires}</p>
                  </div>

                  <div className={`${connectionStatusClass} status`}>
                    <p>{connectionStatus}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="modal-instructions">
                    <p className={`${connectionStatusClass}`}>{instructions}</p>
                  </div>
                  <div className="xumm-qr">
                    <img
                      src={xummObject?.refs.qr_png}
                      alt="qr code"
                    />
                    {connectionStatusClass !== 'ok' && <p className="xumm-qr-expired">EXPIRED</p>}
                  </div>

                  <div className="expires">
                    <p>Expires in {qr_expires}</p>
                  </div>

                  <div className={`${connectionStatusClass} status`}>
                    <p>{connectionStatus}</p>
                  </div>

                  <div className="mobile-warning">
                    <p>
                      If you are on your mobile with XUMM installed and it has not opened
                      automatically{' '}
                      <a
                        href={xummObject?.next.always}
                        target="_blank"
                        className="primary-link"
                        rel="noreferrer"
                      >
                        click here
                      </a>
                    </p>
                  </div>
                </>
              )
            ) : (
              <div className="xumm-loading">
                <div className="xumm-qr">
                  <Loading />
                </div>
                <div className={`${connectionStatusClass} status`}>
                  <p>{loadingMessage}</p>
                </div>
              </div>
            )}
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <p className="concern-statement">
                If you are concerned about connecting your wallet, read
                <a
                  href="https://support.xumm.app/hc/en-us/articles/4427109779986-How-secure-is-Xumm-"
                  target="_blank"
                  rel="noreferrer"
                >
                  this&nbsp;article
                </a>
                on the official XUMM website
              </p>
              <p className="download-xumm">
                We currently only support wallet connection via XUMM, download it here
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="apple-logo">
              <img
                src={AppStore}
                alt="Download from Apple App store"
              />
            </Col>
            <Col className="android-logo">
              <img
                src={GooglePlay}
                alt="Download from Google Play store"
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConnectWallet;
