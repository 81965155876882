import axios from '../api/axios';
import AppContext from '../context/AppProvider';
import { useRef, useState, useEffect, useContext, useMemo } from 'react';
import { Modal, Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import TextInput from './TextInput';
import Warning from './../svg/Warning.svg';
import Success from './../svg/Success.svg';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"@#£$%^&*\-=+_()<>?|{}~;:]).{8,32}$/;
const REGISTER_URL = '/register/rewards';
const UNIQUE_EMAIL_URL = 'register/emailInUse';

const Register = () => {
  // Modal state
  const { showRegister, setShowRegister, setShowLogin } = useContext(AppContext);
  const handleShowLogin = () => {
    clearForm();
    setRegistrationComplete(false);
    setShowRegister(false);
    setShowLogin(true);
  };
  const handleRegisterClose = () => {
    setShowRegister(false);
    setRegistrationComplete(false);
    clearForm();
  };
  useEffect(() => {
    if (showRegister) firstNameRef.current.focus();
  }, [showRegister]);

  //Registration Success
  const [registrationComplete, setRegistrationComplete] = useState(false);

  // Helpers
  const isValidEmail = (val) => {
    return EMAIL_REGEX.test(val);
  };
  const emailsMatch = (email, email2) => {
    return email === email2;
  };
  const isValidPassword = (val) => {
    return PASSWORD_REGEX.test(val);
  };
  const passwordsMatch = (password, password2) => {
    return password === password2;
  };
  const checkEmailInUse = async () => {
    try {
      setTestingUniqueEmail(true);
      return await axios.post(UNIQUE_EMAIL_URL, JSON.stringify({ EmailAddress: email }), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      //TODO: Show the user something useful
    } catch (error) {
      if (error?.message === 'canceled') return;
      if (!error?.response) {
        setErrorMessage('No Server Response');
      } else if (error.response?.status === 409) {
        setErrorMessage('Email Taken');
      } else {
        setErrorMessage('Registration Failed');
      }
    } finally {
      setTestingUniqueEmail(false);
    }
  };

  // Name state
  const [firstName, setFirstName] = useState('');
  const firstNameRef = useRef();
  const [firstNameIsTouched, setFirstNameIsTouched] = useState(false);
  const [firstNameIsValid, setFirstNameIsValid] = useState(false);
  const [firstNameTested, setFirstNameTested] = useState(false);
  const firstNameChangeHandler = (e) => {
    setFirstNameIsTouched(true); // Once changed it has been touched
    setFirstName(e.target.value);
    setFirstNameIsValid(e.target.value !== '');
    setFirstNameTested(true);
  };
  const [lastName, setLastName] = useState('');
  const [lastNameIsTouched, setLastNameIsTouched] = useState(false);
  const [lastNameIsValid, setLastNameIsValid] = useState(false);
  const [lastNameTested, setLastNameTested] = useState(false);
  const lastNameChangeHandler = (e) => {
    setLastNameIsTouched(true); // Once changed it has been touched
    setLastName(e.target.value);
    setLastNameIsValid(e.target.value !== '');
    setLastNameTested(true);
  };
  const nameComplete = useMemo(() => {
    return firstNameIsValid && lastNameIsValid;
  }, [firstNameIsValid, lastNameIsValid]);

  // Email state
  const [email, setEmail] = useState('');
  const emailRef = useRef();
  const [email2, setEmail2] = useState('');
  const [emailIsTouched, setEmailIsTouched] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailTested, setEmailTested] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState('');
  const [email2IsTouched, setEmail2IsTouched] = useState(false);
  const [email2IsValid, setEmail2IsValid] = useState(false);
  const [email2Tested, setEmail2Tested] = useState(false);
  const [email2ValidationMessage, setEmail2ValidationMessage] = useState('');
  const [testingUniqueEmail, setTestingUniqueEmail] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const [emailBackup, setEmailBackup] = useState('');
  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
    setEmailIsTouched(true);
    if (emailTested) {
      testEmail(e.target.value);
    }
  };
  const emailBlurHandler = async (e) => {
    if (!emailIsTouched) return;
    const testPassed = testEmail(e.target.value);
    if (testPassed) {
      // Email is valid so check uniqueness with api
      let emailOk = false;
      if (e.target.value !== emailBackup) {
        setEmailBackup(e.target.value);
        const emailInUseTest = await checkEmailInUse(e.target.value);
        if (emailInUseTest.data) {
          setEmailInUse(true);
          emailOk = false;
          setEmailValidationMessage('Email address is in use');
        } else {
          setEmailInUse(false);
          emailOk = true;
          setEmailValidationMessage('');
        }
      }
      if (emailIsValid && emailOk) {
        setEmailValidationMessage('');
      }
      if (email2IsTouched) {
        testEmail2(email2);
      }
    }
  };
  const testEmail = (val) => {
    const testPassed = isValidEmail(val);
    if (testPassed) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
    setEmailTested(true);
    return testPassed;
  };
  const email2ChangeHandler = (e) => {
    setEmail2(e.target.value);
    setEmail2IsTouched(true);
    if (email2Tested || (emailIsValid && e.target.value === email)) {
      testEmail2(e.target.value);
    }
  };
  const email2BlurHandler = (e) => {
    if (!email2IsTouched) return;
    testEmail2(e.target.value);
  };
  const testEmail2 = (val) => {
    if (emailsMatch(email, val)) {
      setEmail2IsValid(true);
      setEmail2ValidationMessage('');
    } else {
      setEmail2IsValid(false);
      setEmail2ValidationMessage('Email address must match');
    }
    setEmail2Tested(true);
  };
  const emailComplete = useMemo(() => {
    return isValidEmail(email) && emailsMatch(email, email2);
  }, [email, email2]);

  // Password state
  const [password, setPassword] = useState('');
  const passwordRef = useRef();
  const [passwordIsTouched, setPasswordIsTouched] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordTested, setPasswordTested] = useState(false);
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const [password2, setPassword2] = useState('');
  const [password2IsTouched, setPassword2IsTouched] = useState(false);
  const [password2IsValid, setPassword2IsValid] = useState(false);
  const [password2Tested, setPassword2Tested] = useState(false);
  const [password2ValidationMessage, setPassword2ValidationMessage] = useState('');
  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
    setPasswordIsTouched(true);
    if (passwordTested) {
      testPassword(e.target.value);
    }
  };
  const passwordBlurHandler = (e) => {
    if (!passwordIsTouched) return;
    testPassword(e.target.value);
  };
  const testPassword = (val) => {
    if (isValidPassword(val)) {
      setPasswordIsValid(true);
      setPasswordValidationMessage('');
    } else {
      setPasswordIsValid(false);
      setPasswordValidationMessage(
        'Password must be a mix of lower/upper numeric and special characters'
      );
    }
    setPasswordTested(true);
    if (isValidPassword(val) && password2IsTouched) {
      testPassword2(password2);
    }
  };
  const password2ChangeHandler = (e) => {
    setPassword2(e.target.value);
    setPassword2IsTouched(true);
    if (password2Tested || (passwordIsValid && e.target.value === password)) {
      testPassword2(e.target.value);
    }
  };
  const password2BlurHandler = (e) => {
    if (!password2IsTouched) return;
    testPassword2(e.target.value);
  };
  const testPassword2 = (val) => {
    if (passwordsMatch(password, val)) {
      setPassword2IsValid(true);
      setPassword2ValidationMessage('');
    } else {
      setPassword2IsValid(false);
      setPassword2ValidationMessage('Passwords must match');
    }
    setPassword2Tested(true);
  };
  const passwordComplete = useMemo(() => {
    return isValidPassword(password) && passwordsMatch(password, password2);
  }, [password, password2]);

  // Cleanup
  const clearForm = () => {
    setFirstName('');
    setFirstNameIsTouched(false);
    setFirstNameIsValid(false);
    setLastName('');
    setLastNameIsTouched(false);
    setLastNameIsValid(false);

    setEmail('');
    setEmailIsTouched(false);
    setEmailIsValid(false);
    setEmailTested(false);
    setEmailValidationMessage('');
    setEmail2('');
    setEmail2IsTouched(false);
    setEmail2IsValid(false);
    setEmail2Tested(false);
    setEmail2ValidationMessage('');
    setTestingUniqueEmail(false);
    setEmailInUse(false);
    setEmailBackup('');

    setPassword('');
    setPasswordIsTouched(false);
    setPasswordIsValid(false);
    setPasswordTested(false);
    setPasswordValidationMessage('');
    setPassword2('');
    setPassword2IsTouched(false);
    setPassword2IsValid(false);
    setPassword2Tested(false);
    setPassword2ValidationMessage('');

    setCurrentTab('user');
    setErrorMessage('');
  };

  // Submission
  const [registering, setRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const userComplete = useMemo(() => {
    return nameComplete && emailComplete && passwordComplete;
  }, [nameComplete, emailComplete, passwordComplete]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = EMAIL_REGEX.test(email);
    const v2 = PASSWORD_REGEX.test(password);
    if (!v1 || !v2) {
      setErrorMessage('Invalid Entry');
      return;
    }
    try {
      setRegistering(true);
      await axios.post(
        REGISTER_URL,
        JSON.stringify({
          FirstName: firstName,
          LastName: lastName,
          EmailAddress: email,
          Password: password,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );

      setRegistrationComplete(true);
      clearForm();
    } catch (error) {
      if (error?.message === 'canceled') return;
      if (!error?.response) {
        setErrorMessage('No Server Response');
      } else if (error.response?.status === 409) {
        setErrorMessage('Email Taken');
      } else {
        setErrorMessage('Registration Failed');
      }
    } finally {
      setRegistering(false);
    }
  };

  // Tab state & management
  const [currentTab, setCurrentTab] = useState('user');
  const tabSelectHandler = (eventKey) => {
    if (eventKey === 'user') return setCurrentTab(eventKey);
    if (eventKey === 'email' && nameComplete) return setCurrentTab(eventKey);
    if (eventKey === 'password' && nameComplete && emailComplete) return setCurrentTab(eventKey);
  };
  const nextButtonHandler = () => {
    if (currentTab === 'user' && nameComplete) {
      tabSelectHandler('email');
      setTimeout(() => {
        emailRef.current.focus();
      }, 200);
      return;
    }
    if (currentTab === 'email' && nameComplete && emailComplete) {
      tabSelectHandler('password');
      setTimeout(() => {
        passwordRef.current.focus();
      }, 200);
      return;
    }
  };

  return (
    <>
      <Modal
        show={showRegister}
        size="lg"
        fullscreen="md-down"
      >
        <Modal.Header>
          <Button
            onClick={handleRegisterClose}
            className="btn-close circle"
          ></Button>
        </Modal.Header>
        <Modal.Body className="register-modal">
          <>
            {registrationComplete ? (
              <section>
                <div className="icon">
                  <img
                    src={Success}
                    alt="Success icon"
                  />
                </div>
                <div className="modal-title">Registration Successful</div>
                <div className="modal-instructions">
                  We have sent you an email, the next step is to validate your email by clicking the
                  link in the email.
                </div>
                <div className="modal-instructions">
                  If the email has not arrived within a few minutes please check your junk mail
                  folder, failing that please raise a support request from the menu.
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <Button
                    className="modal-primary-cta"
                    onClick={handleShowLogin}
                  >
                    Sign in
                  </Button>
                </div>
              </section>
            ) : (
              <section>
                <div className="modal-title">Register an account</div>
                <div className="modal-instructions">All fields are required</div>

                {errorMessage !== '' && (
                  <Row
                    className="error-message mb-4 me-0 ms-0"
                    aria-live="assertive"
                  >
                    <Col className="flex-grow-0 align-self-center">
                      <img
                        src={Warning}
                        alt="Warning"
                      />
                    </Col>
                    <Col className="flex-grow-1 align-self-center">{errorMessage}</Col>
                  </Row>
                )}

                <form onSubmit={handleSubmit}>
                  <Tabs
                    id="fill-tab-example"
                    fill
                    activeKey={currentTab}
                    onSelect={(e) => tabSelectHandler(e)}
                  >
                    <Tab
                      eventKey="user"
                      tabAttrs={{ className: nameComplete && 'completed' }}
                      title={<div className="icon icon-user"></div>}
                    >
                      <Row>
                        <Col
                          lg={6}
                          className="mb-4 mb-lg-0"
                        >
                          <TextInput
                            id="firstName"
                            type="text"
                            value={firstName}
                            setValue={firstNameChangeHandler}
                            label="First name"
                            inputRef={firstNameRef}
                            isValid={firstNameIsValid}
                            isTouched={firstNameIsTouched}
                            isTested={firstNameTested}
                            invalidMessage="First name is required"
                            autoComplete="given-name"
                          />
                        </Col>
                        <Col lg={6}>
                          <TextInput
                            id="lastName"
                            type="text"
                            value={lastName}
                            setValue={lastNameChangeHandler}
                            label="Last name"
                            isValid={lastNameIsValid}
                            isTouched={lastNameIsTouched}
                            isTested={lastNameTested}
                            invalidMessage="Last name is required"
                            autoComplete="family-name"
                          />
                        </Col>
                      </Row>
                    </Tab>
                    <Tab
                      eventKey="email"
                      tabAttrs={{ className: emailComplete && 'completed' }}
                      title={<div className="icon icon-email"></div>}
                    >
                      <Row>
                        <Col
                          lg={6}
                          className="mb-4 mb-lg-0"
                        >
                          <TextInput
                            id="email"
                            type="email"
                            value={email}
                            setValue={emailChangeHandler}
                            label="Email address"
                            inputRef={emailRef}
                            isValid={emailIsValid && !emailInUse}
                            isTouched={emailIsTouched}
                            isTested={emailTested}
                            invalidMessage={emailValidationMessage}
                            onBlur={emailBlurHandler}
                            showSpinner={testingUniqueEmail}
                            autoComplete="email"
                          />
                        </Col>
                        <Col lg={6}>
                          <TextInput
                            id="email2"
                            type="email"
                            value={email2}
                            setValue={email2ChangeHandler}
                            label="Confirm email address"
                            isValid={emailIsValid && !emailInUse && email2IsValid}
                            isTouched={email2IsTouched}
                            isTested={email2Tested}
                            invalidMessage={email2ValidationMessage}
                            onBlur={email2BlurHandler}
                            autoComplete="email"
                          />
                        </Col>
                      </Row>
                    </Tab>
                    <Tab
                      eventKey="password"
                      tabAttrs={{ className: passwordComplete && 'completed' }}
                      title={<div className="icon icon-password"></div>}
                    >
                      <Row>
                        <Col
                          lg={6}
                          className="mb-4 mb-lg-0"
                        >
                          <TextInput
                            id="password"
                            type="password"
                            value={password}
                            setValue={passwordChangeHandler}
                            label="Password"
                            inputRef={passwordRef}
                            isValid={passwordIsValid}
                            isTouched={passwordIsTouched}
                            isTested={passwordTested}
                            invalidMessage={passwordValidationMessage}
                            onBlur={passwordBlurHandler}
                            autoComplete="new-password"
                          />
                        </Col>
                        <Col lg={6}>
                          <TextInput
                            id="password2"
                            type="password"
                            value={password2}
                            setValue={password2ChangeHandler}
                            label="Confirm your password"
                            isValid={password2IsValid}
                            isTouched={password2IsTouched}
                            isTested={password2Tested}
                            invalidMessage={password2ValidationMessage}
                            onBlur={password2BlurHandler}
                            autoComplete="new-password"
                          />
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>

                  <Container>
                    <Row>
                      <Col className="d-flex justify-content-center mt-5">
                        {nameComplete && emailComplete && currentTab === 'password' ? (
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={registering || !userComplete}
                            onClick={(e) => tabSelectHandler('password')}
                          >
                            Register
                            {registering && <div className="spinner btn-spinner"></div>}
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            type="button"
                            disabled={
                              (currentTab === 'user' && !nameComplete) ||
                              (currentTab === 'email' && !emailComplete)
                            }
                            onClick={nextButtonHandler}
                          >
                            Next
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="modal-links">
                        <div>
                          <span className="me-2">
                            <span className="d-sm-none">Got an account?</span>
                            <span className="d-none d-sm-inline">Already have an account?</span>
                          </span>
                          <span
                            className="link"
                            onClick={handleShowLogin}
                          >
                            Sign in
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </section>
            )}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;
