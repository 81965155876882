import { useState, useEffect } from 'react';

import TextInput from './../components/TextInput';
import Pen from '../svg/Pen.svg';
import Save from '../svg/Save.svg';
import Cross from '../svg/Cross.svg';

const EditableTextInput = (props) => {
  const { id, type, inputRef, label, inputSaveHandler, initialValue } = props;

  // Personal details state
  const [inputLocked, setInputLocked] = useState(true);

  const saveHandler = () => {
    setInputLocked(true);
    inputSaveHandler();
  };

  const cancelHandler = () => {
    setInputLocked(true);
    inputRef.current.value = initialValue;
  };

  const editHandler = () => {
    setInputLocked(false);
    setTimeout(() => {
      const len = inputRef.current.value.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(len, len);
    }, 200);
  };

  useEffect(() => {
    inputRef.current.value = initialValue;
  }, [initialValue, inputRef]);

  return (
    <div className="input-edit-container">
      <div className="input-edit">
        <TextInput
          id={id}
          type={type}
          inputRef={inputRef}
          label={label}
          disabled={inputLocked}
          locked={inputLocked}
        />
      </div>
      <div className="input-edit-icon">
        {inputLocked ? (
          <img
            onClick={editHandler}
            className="icon"
            src={Pen}
            alt="Edit icon"
          />
        ) : (
          <>
            <img
              onClick={saveHandler}
              className="icon"
              src={Save}
              alt="Update field"
            />
            <img
              onClick={cancelHandler}
              className="icon ms-2"
              src={Cross}
              alt="Cancel"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default EditableTextInput;
