const Banner = (props) => {
  return (
    <>
      <div className="bg">
        <img
          src={props.icon}
          alt="Crown icon"
        />
        <h1 className="title text-white-bold">{props.title}</h1>
        <p className="message">
          <span className="first-break">
            <span className="second-break">{props.message1a}</span>
            <span className="second-break">{props.message1b}</span>
          </span>
          <span className="first-break">
            <span className="second-break">{props.message2a}</span>
            <span className="second-break">{props.message2b}</span>
          </span>
        </p>
        <a
          className="btn btn-outline-primary"
          href={props.buttonLink}
          target="_blank"
          rel="noreferrer"
          role="button"
        >
          {props.buttonTitle}
        </a>
      </div>
    </>
  );
};

export default Banner;
