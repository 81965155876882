import { useState, useContext, useEffect } from 'react';
import AppContext from '../../context/AppProvider';
import AuthContext from '../../context/AuthProvider';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useError from '../../hooks/useError';

import { Container, Row, Col } from 'react-bootstrap';

import { v4 as uuidv4 } from 'uuid';
import ToastType from '../../enums/toastType';

import Strap from './Strap';
import Builder1 from './Builder1';
import Builder2 from './Builder2';
import Builder3 from './Builder3';
import Ineligible from './Ineligible';

import Confirm from '../../components/Confirm';

const MIN_STAKING_AMOUNT = +process.env.REACT_APP_MIN_STAKING_AMOUNT;

const CreatePlan = () => {
  const axiosPrivate = useAxiosPrivate();
  const errorHandler = useError();

  const { setToast } = useContext(AppContext);
  const { setWallets, currentWallet } = useContext(AuthContext);

  // stage management
  const [stage1Complete, setStage1Complete] = useState(false);
  const [stage2Complete, setStage2Complete] = useState(false);
  const [stage3Complete, setStage3Complete] = useState(false);
  const [ineligible, setIneligible] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [disableFinishButton, setDisableFinishButton] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(false);

  // hold plan setup data for chosen wallet
  const [planSetup, setPlanSetup] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const [calculatedGains, setCalculatedGains] = useState();

  useEffect(() => {
    setStage1Complete(false);
    setStage2Complete(false);
    setStage3Complete(false);
    setIneligible(false);
  }, [currentWallet]);

  const termsAgreedHandler = async () => {
    const controller = new AbortController();

    try {
      if (!currentWallet) throw new Error('NO_CURRENT_WALLET');
      const response = await axiosPrivate.get(`/rewards/startplan?walletAddress=${currentWallet}`, {
        signal: controller.signal,
      });
      if (response.data?.balanceBpm < MIN_STAKING_AMOUNT) {
        setIneligible(true);
        return;
      }

      setPlanSetup(response.data);
      setStage1Complete(true);
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
    }
  };

  const planCreatedHandler = async (amount, period, amountPercentage) => {
    setCurrentPlan({
      bpmLockupAmount: amount,
      lockupPeriodMonth: +period,
      amountPercentage: amountPercentage,
    });

    const controller = new AbortController();
    try {
      const response = await axiosPrivate.post(
        `/rewards/calculateGains?walletAddress=${currentWallet}`,
        {
          bpmLockupAmount: amount,
          lockupPeriodMonth: +period, // Always numeric
        },
        {
          signal: controller.signal,
        }
      );
      setCalculatedGains(response.data);

      setStage2Complete(true);
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
    }
  };

  const generatePlanHandler = () => {
    setShowConfirm(true);
    setDisableFinishButton(true);
    setDisableEditButton(true);
  };

  const backToCreatePlan = () => {
    setStage2Complete(false);
  };

  const confirmCloseHandler = () => {
    setDisableFinishButton(false);
    setDisableEditButton(false);
    setShowConfirm(false);
  };

  const confirmAcceptHandler = async () => {
    setShowConfirm(false);
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.post(
        `/rewards/createRewardAccount?walletAddress=${currentWallet}`,
        {
          bpmLockupAmount: currentPlan.bpmLockupAmount,
          lockupPeriodMonth: currentPlan.lockupPeriodMonth, // Always numeric
          autoRenew: 0,
        },
        {
          signal: controller.signal,
        }
      );
      if (response) {
        setWallets((prev) => {
          const t = prev.map((wallet) => {
            if (wallet.id === currentWallet) {
              return { ...wallet, hasRewardAccount: true };
            }
            return wallet;
          });

          return t;
        });

        setToast((prev) => {
          return [
            ...prev,
            {
              id: uuidv4(),
              type: ToastType.success,
              title: 'Success',
              message: 'Plan set up successfully',
            },
          ];
        });

        setStage3Complete(true);
        return;
      }
      throw new Error('Failed to set up plan');
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
    }
  };

  const confirmRejectHandler = () => {
    setDisableFinishButton(false);
    setDisableEditButton(false);
    setShowConfirm(false);
  };

  return (
    <>
      <section className="reward-create-plan-container">
        <Container className="reward-create-plan">
          <Row>
            <Col
              xs={12}
              lg={6}
              className="plan-text-container"
            >
              <Strap
                head1="Build your"
                head2="rewards plan"
                text="Using our custom plans, you can decide how much $BPM to lock-up and for how long."
              />
            </Col>
            <Col
              xs={12}
              lg={6}
            >
              {ineligible ? (
                <div className="reward-builder ineligible">
                  <Ineligible />
                </div>
              ) : (
                <div className="reward-builder">
                  {!stage1Complete && <Builder1 termsAgreedHandler={termsAgreedHandler} />}

                  {stage1Complete && !stage2Complete && (
                    <Builder2
                      planCreatedHandler={planCreatedHandler}
                      planSetup={planSetup}
                      currentPlan={currentPlan}
                    />
                  )}

                  {stage1Complete && stage2Complete && !stage3Complete && (
                    <Builder3
                      generatePlanHandler={generatePlanHandler}
                      backToCreatePlan={backToCreatePlan}
                      calculatedGains={calculatedGains}
                      disableFinishButton={disableFinishButton}
                      disableEditButton={disableEditButton}
                    />
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      <Confirm
        title="Confirm lockup"
        question="Are you sure you want to proceed?"
        acceptText="Proceed"
        rejectText="Cancel"
        closeHandler={confirmCloseHandler}
        acceptHandler={confirmAcceptHandler}
        rejectHandler={confirmRejectHandler}
        show={showConfirm}
      />
    </>
  );
};
export default CreatePlan;
