import { Row, Col } from 'react-bootstrap';

const PotentialRewards = ({ eligibleUnstakedAmount, potentialRewards }) => {
  return (
    <Row>
      <Col>
        <p className="missed-opportunity">
          You have{' '}
          {eligibleUnstakedAmount &&
            eligibleUnstakedAmount.toLocaleString('en', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
          BPM unstaked.
          <br />
          Lock this up and earn an additional{' '}
          {potentialRewards &&
            potentialRewards.toLocaleString('en', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
          $BPM.
          <br />
          Note: increasing your lock-up amount does not extend the redemption date.
        </p>
      </Col>
    </Row>
  );
};

export default PotentialRewards;
