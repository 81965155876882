import { useState, useRef } from 'react';

import { Button, Form } from 'react-bootstrap';

const MIN_STAKING_AMOUNT = +process.env.REACT_APP_MIN_STAKING_AMOUNT;
const MAX_STAKING_AMOUNT = +process.env.REACT_APP_MAX_STAKING_AMOUNT;

const Builder1 = ({ termsAgreedHandler }) => {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [starting, setStarting] = useState(false);

  const termsRef = useRef();

  const termsChangeHandler = () => {
    setTermsAgreed(termsRef.current.checked);
  };

  const startHandler = () => {
    if (termsAgreed) {
      setStarting(true);
      termsAgreedHandler();
    }
  };

  return (
    <>
      <div className="builder builder1">
        <div className="title">TERMS & CONDITIONS</div>
        <ul>
          <li>$BPM remains in your custody - daily snapshots track your balance.</li>
          <li>
            Your rewards are calculated daily based on how much you lock up, not how much is in your
            wallet.
          </li>
          <li>Your rewards are paid at the end of your fixed term.</li>
          <li>
            If your $BPM balance increases, you can modify your plan and increase your lock-up
            amount, but you cannot lower it.
          </li>
          <li>The minimum staking value is 1,000 $BPM.</li>
          <li>The maximum staking value is 20,000,000 $BPM.</li>
          <li>
            You will lose ALL rewards earned if your wallet balance drops below the amount you lock
            up.
          </li>
          <li>
            We reserve the right to pause or reduce the lock up percentages at any time if it is to 
            the benefit of the overall ecosystem.
          </li>
          <li>
            Full terms and conditions available{' '}
            <a
              href="https://bpmwallet.io/terms"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </li>
        </ul>

        <Form.Check
          type="checkbox"
          id="terms"
          label="I have read and agree to the terms and conditions"
          ref={termsRef}
          onChange={termsChangeHandler}
          disabled={starting}
        />
        <Button
          onClick={startHandler}
          disabled={!termsAgreed || starting}
        >
          Start your plan{starting && <div className="spinner btn-spinner"></div>}
        </Button>
      </div>
    </>
  );
};

export default Builder1;
