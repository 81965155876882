import { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import AuthContext from "../context/AuthProvider";

const RequireAuth = ({ allowedRoles }) => {
  const { token, roles } = useContext(AuthContext);
  const location = useLocation();

  return token && roles.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate
      to="/unauthorized"
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
