import { Outlet, Link } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

import Logo from '../components/Logo';
import Social from '../components/Social';
import FooterLinks from '../components/FooterLinks';
import Menu from '../components/Menu';
import Login from '../components/Login';
import Register from '../components/Register';
import Forgot from '../components/Forgot';
import ConnectWallet from '../components/ConnectWallet';
import Message from '../components/Message';
import RewardPlans from '../components/RewardPlans';

const Layout = ({ ROLES }) => {
  return (
    <>
      <main className="app">
        <Menu ROLES={ROLES} />
        <Outlet />
      </main>
      <footer>
        <Container>
          <Row>
            <Col
              md={{ span: 6, order: 2 }}
              className="d-flex justify-content-center justify-content-md-start align-items-center bpm-logo"
            >
              <Link to="/">
                <Logo />
              </Link>
            </Col>
            <Col
              md={{ order: 5 }}
              className="d-flex justify-content-center justify-content-md-end align-items-start footer-links mt-2 mb-4"
            >
              <FooterLinks />
            </Col>
            <Col
              md={{ span: 6, order: 3 }}
              className="d-flex justify-content-center justify-content-md-end align-items-center social-icons"
            >
              <Social />
            </Col>
            <Col
              md={{ order: 4 }}
              className="d-flex justify-content-center justify-content-md-start align-items-start copyright-notice mt-2 mb-4"
            >
              <div>Copyright © 2022.</div>
              <div>All rights reserved by BPM.</div>
            </Col>
          </Row>
        </Container>
      </footer>
      <Login />
      <Register />
      <Forgot />
      <ConnectWallet />
      <Message />
      <RewardPlans />
    </>
  );
};

export default Layout;
