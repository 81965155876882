const FooterLinks = () => {
  return (
    <>
      <a
        href="https://bpmwallet.io/images/whitepaper.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Whitepaper
      </a>
      <a
        href="https://bpmwallet.io/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy&nbsp;Policy
      </a>
      <a
        href="https://bpmwallet.io/legal-disclaimer"
        target="_blank"
        rel="noreferrer"
      >
        Legal&nbsp;Disclaimer
      </a>
    </>
  );
};

export default FooterLinks;
