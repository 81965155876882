import { Container, Row, Col } from 'react-bootstrap';

const Unauthorized = () => {
  return (
    <>
      <section className="hero">
        <Container className="home-hero">
          <Row>
            <Col>
              <h1 className="text-white-outline">Unauthorized</h1>
              <p>You are trying to access a page that requires you to be logged in.</p>
              <p>
                Prevent this from happening in the future, by ticking the 'stay signed in' checkbox
                during the login process.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Unauthorized;
