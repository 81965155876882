const Social = () => {
  return (
    <>
      <a
        href="https://twitter.com/bpmwallet"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/images/social/Twitter.png"
          srcSet="/images/social/Twitter.webp 32w, /images/social/Twitter.png 32w, /images/social/Twitter@2.webp 64w, /images/social/Twitter@2.png 64w"
          sizes="(max-width: 1600px) 32px"
          alt="Twitter Logo"
        ></img>
      </a>
      {/* <a
        href="https://www.facebook.com/bpmwallet"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/images/social/Facebook.png"
          srcSet="/images/social/Facebook.webp 32w, /images/social/Facebook.png 32w, /images/social/Facebook@2.webp 64w, /images/social/Facebook@2.png 64w"
          sizes="(max-width: 1600px) 32px"
          alt="Facebook Logo"
        ></img>
      </a> */}
      <a
        href="https://discord.com/invite/SPzGu6Dsfn"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/images/social/Discord.png"
          srcSet="/images/social/Discord.webp 32w, /images/social/Discord.png 32w, /images/social/Discord@2.webp 64w, /images/social/Discord@2.png 64w"
          sizes="(max-width: 1600px) 32px"
          alt="Discord Logo"
        ></img>
      </a>
      {/* <a
        href="https://www.instagram.com/bpmwallet/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/images/social/Instagram.png"
          srcSet="/images/social/Instagram.webp 32w, /images/social/Instagram.png 32w, /images/social/Instagram@2.webp 64w, /images/social/Instagram@2.png 64w"
          sizes="(max-width: 1600px) 32px"
          alt="Instagram Logo"
        ></img>
      </a> */}
    </>
  );
};

export default Social;
