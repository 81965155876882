import { Modal, Button } from 'react-bootstrap';

const Confirm = ({
  title,
  question,
  acceptText,
  rejectText,
  closeHandler,
  acceptHandler,
  rejectHandler,
  show,
}) => {
  const handleClose = () => {
    closeHandler();
  };
  const handleReject = () => {
    rejectHandler();
  };
  const handleAccept = () => {
    acceptHandler();
  };

  return (
    <>
      <Modal
        className="confirm-modal"
        show={show}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Button
            onClick={handleClose}
            className="btn-close circle"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: question }} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleReject}
            size="sm"
          >
            {rejectText}
          </Button>
          <Button
            variant="primary"
            onClick={handleAccept}
            size="sm"
          >
            {acceptText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Confirm;
