import { createContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';

const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const isMobileSize = useMediaQuery({ maxWidth: 767 });
  const isMobileAgent = isMobile;

  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [toast, setToast] = useState([]);
  const [isMobileDevice, setMobileDevice] = useState(isMobileAgent && isMobileSize);
  const [showRewardPlans, setShowRewardPlans] = useState(false);

  return (
    <AppContext.Provider
      value={{
        showLogin,
        setShowLogin,
        showRegister,
        setShowRegister,
        showForgot,
        setShowForgot,
        showConnectWallet,
        setShowConnectWallet,
        toast,
        setToast,
        isMobileDevice,
        setMobileDevice,
        showRewardPlans,
        setShowRewardPlans,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
