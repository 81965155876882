const Logo = () => {
  return (
    <img
      src="/images/bpmlogo@64.png"
      srcSet="/images/bpmlogo@48.webp 98w, /images/bpmlogo@48.png 98w, /images/bpmlogo@64.webp 131w, /images/bpmlogo@64px.png 131w, /images/bpmlogo@128.webp 260w, /images/bpmlogo@128.png 260w"
      sizes="(max-width: 575px) 98px, (max-width: 1600px) 131px, 260px"
      alt="BPM Logo"
    ></img>
  );
};

export default Logo;
