import { useRef, useContext } from 'react';
import AuthContext from '../context/AuthProvider';
import { Container, Row, Col, Form } from 'react-bootstrap';

import Wallet from '../svg/Wallet.svg';

const WalletSelector = (props) => {
  const { wallets, currentWallet, setCurrentWallet } = useContext(AuthContext);

  // Refs
  const walletsRef = useRef();

  const walletChangeHandler = (e) => {
    setCurrentWallet(wallets.find((wallet) => wallet.id === e.target.value)?.id);
  };

  return (
    <section className="wallet-selector-container">
      <Container className="wallet-selector">
        <Row>
          <Col>
            <img
              className="wallet-icon"
              src={Wallet}
              alt="Wallet icon"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="info-text">
              <h1 className="text-white-bold">{props.title}</h1>
              <p>{props.message}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Select
              aria-label="Wallet selector"
              ref={walletsRef}
              onChange={walletChangeHandler}
              defaultValue={currentWallet}
            >
              {wallets.map((wallet) => (
                <option
                  value={wallet.id}
                  key={wallet.id}
                >
                  {wallet.friendlyName ? wallet.friendlyName : wallet.id}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WalletSelector;
