import { useEffect, useContext, useState, useMemo } from 'react';

import AuthContext from '../../context/AuthProvider';
import AppContext from '../../context/AppProvider';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useError from '../../hooks/useError';

import { Container, Row, Col, ProgressBar, Button } from 'react-bootstrap';

import { v4 as uuidv4 } from 'uuid';
import ToastType from '../../enums/toastType';

import Strap from './Strap';
import PotentialRewards from './PotentialRewards';
import Loading from '../Loading';
import Confirm from '../../components/Confirm';
import Modify from './Modify';

import Lock from '../../svg/Lock.svg';
import Clock from '../../svg/Clock.svg';
import Reveller from '../../svg/Reveller.svg';
import Tiers from '../../svg/Tiers.svg';
import Pen from '../../svg/Pen.svg';
import History from './History';
import Bin from '../../svg/Bin.svg';
import AutoRenew from '../../svg/AutoRenew.svg';

const MIN_POTENTIAL_REWARDS_FOR_MODIFY = +process.env.REACT_APP_MIN_POTENTIAL_REWARDS_FOR_MODIFY;

const Summary = () => {
  const axiosPrivate = useAxiosPrivate();
  const errorHandler = useError();

  const { currentWallet, setWallets } = useContext(AuthContext);
  const { setToast } = useContext(AppContext);

  const [showConfirm, setShowConfirm] = useState(false);
  const [showModify, setShowModify] = useState(false);
  const [modifying, setModifying] = useState(false);

  const [rewardsSummary, setRewardsSummary] = useState();
  const [modifyPlanSetup, setModifyPlanSetup] = useState({
    balanceBpm: 0,
    lockedBpm: 0,
    difference: 0,
    months: [],
  });

  const modifyDisabled = useMemo(() => {
    return (
      modifying ||
      (rewardsSummary?.eligibleUnstakedAmount <= MIN_POTENTIAL_REWARDS_FOR_MODIFY &&
        rewardsSummary?.period === 12)
    );
  }, [modifying, rewardsSummary]);

  useEffect(() => {
    let isMounted = true;
    isMounted && setRewardsSummary();
    const controller = new AbortController();

    const getSummary = async () => {
      try {
        const response = await axiosPrivate.get(
          `/rewards/GetRewardAccount?walletAddress=${currentWallet}`,
          {
            signal: controller.signal,
          }
        );

        isMounted && setRewardsSummary(response.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        controller.abort();
      }
    };

    getSummary();

    return () => {
      controller.abort();
      isMounted = false;
    };
  }, [axiosPrivate, currentWallet, errorHandler, setToast]);

  const autoRenewHandler = async () => {
    const controller = new AbortController();
    const ar = !rewardsSummary.autoRenew;

    try {
      await axiosPrivate.post(
        `/rewards/updateAutoRenew?walletAddress=${currentWallet}`,
        {
          autoRenew: ar,
        },
        {
          signal: controller.signal,
        }
      );

      setRewardsSummary((prev) => {
        return { ...prev, autoRenew: ar };
      });

      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ar ? ToastType.success : ToastType.warning,
            title: 'Auto renew',
            message: ar
              ? 'Auto renew has been successfully enabled'
              : 'Auto renew has been successfully disabled',
          },
        ];
      });

      controller.abort();
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
    }
  };

  const cancelPlanHandler = async () => {
    setShowConfirm(true);
  };

  const confirmCloseHandler = () => {
    setShowConfirm(false);
  };

  const confirmAcceptHandler = async () => {
    setShowConfirm(false);
    const controller = new AbortController();
    try {
      await axiosPrivate.get(`/rewards/cancelRewardsPlan?walletAddress=${currentWallet}`, {
        signal: controller.signal,
      });

      setRewardsSummary();

      setWallets((prev) => {
        const t = prev.map((wallet) => {
          if (wallet.id === currentWallet) {
            return { ...wallet, hasRewardAccount: false };
          }
          return wallet;
        });

        return t;
      });

      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.success,
            title: 'Plan cancellation',
            message: 'Plan cancelled successfully',
          },
        ];
      });

      controller.abort();
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
    }
  };

  const confirmRejectHandler = () => {
    setShowConfirm(false);
  };

  const modifyHandler = async () => {
    setModifying(true);
    const controller = new AbortController();

    try {
      const response = await axiosPrivate.get(
        `/rewards/modifyplan?walletAddress=${currentWallet}`,
        {
          signal: controller.signal,
        }
      );

      setModifyPlanSetup(response.data);
      setShowModify(true);
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
      setModifying(false);
    }
  };

  const modifyCloseHandler = () => {
    setShowModify(false);
    setModifying(false);
  };

  const modifyAcceptHandler = async (amount, period) => {
    const controller = new AbortController();

    try {
      const response = await axiosPrivate.put(
        `/rewards/updateRewardAccount?walletAddress=${currentWallet}`,
        {
          bpmLockupAmount: amount,
          lockupPeriodMonth: period,
          autoRenew: 1,
        },
        {
          signal: controller.signal,
        }
      );

      if (response) {
        setRewardsSummary(response.data);

        setToast((prev) => {
          return [
            ...prev,
            {
              id: uuidv4(),
              type: ToastType.success,
              title: 'Success',
              message: 'Plan updated successfully',
            },
          ];
        });

        setShowModify(false);
        setModifying(false);
        return;
      }
      throw new Error('Failed to set up plan');
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
    }
  };

  const modifyRejectHandler = () => {
    setShowModify(false);
    setModifying(false);
  };

  return (
    <>
      <section className="rewards-summary-container">
        <Container className="reward-summary">
          <Row>
            <Col
              xs={12}
              lg={6}
              className="plan-text-container"
            >
              <Strap
                head1="Your active"
                head2="rewards plan"
                text="Using our custom plans, you can decide how much $BPM to lock-up and for how long."
              />
            </Col>
            <Col
              xs={12}
              lg={6}
            >
              <div className="plan-summary">
                <Row>
                  <Col>
                    <p className="summary-title">ACTIVE PLAN</p>
                  </Col>
                </Row>
                <Row className="summary-icons">
                  <Col
                    xs={6}
                    sm={3}
                  >
                    <div className="icon">
                      <img
                        src={Lock}
                        alt="Lock icon"
                      />
                    </div>
                    {rewardsSummary?.lockupAmount ? (
                      <>
                        <div className="title">Locked</div>
                        <div className="value">
                          {rewardsSummary?.lockupAmount.toLocaleString('en', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </>
                    ) : (
                      <Loading />
                    )}
                  </Col>
                  <Col
                    xs={6}
                    sm={3}
                  >
                    <div className="icon">
                      <img
                        src={Clock}
                        alt="Clock icon"
                      />
                    </div>
                    {rewardsSummary?.period ? (
                      <>
                        <div className="title">Period</div>
                        <div className="value">{rewardsSummary?.period} Months</div>
                      </>
                    ) : (
                      <Loading />
                    )}
                  </Col>
                  <Col
                    xs={6}
                    sm={3}
                  >
                    <div className="icon">
                      <img
                        src={Tiers}
                        alt="Tier icon"
                      />
                    </div>
                    {rewardsSummary?.bpmTier ? (
                      <>
                        <div className="title">Qty</div>
                        <div className="value">Level {rewardsSummary?.bpmTier}</div>
                      </>
                    ) : (
                      <Loading />
                    )}
                  </Col>
                  <Col
                    xs={6}
                    sm={3}
                  >
                    <div className="icon">
                      <img
                        src={Reveller}
                        alt="Reveller icon"
                      />
                    </div>
                    {rewardsSummary?.revellerTier ? (
                      <>
                        <div className="title">Reveller</div>
                        <div className="value">{rewardsSummary?.revellerTier}</div>
                      </>
                    ) : (
                      <Loading />
                    )}
                  </Col>
                </Row>
                <Row className="progress-container">
                  <Col xs={12}>
                    <ProgressBar
                      now={
                        100 -
                        (Math.round(rewardsSummary?.daysToGo) /
                          Math.round(rewardsSummary?.daysOnPlan)) *
                          100
                      }
                    />
                  </Col>
                  <Col xs={5}>
                    {rewardsSummary && rewardsSummary?.pendingRewards > 0 && (
                      <div className="increase-to-date">
                        +
                        {rewardsSummary?.pendingRewards.toLocaleString('en', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                        BPM
                      </div>
                    )}
                  </Col>
                  <Col xs={7}>
                    {rewardsSummary && rewardsSummary?.daysToGo > 0 && (
                      <div className="days-to-go">
                        <span>{Math.round(rewardsSummary?.daysToGo)}</span> days to go
                      </div>
                    )}
                  </Col>
                </Row>
                {rewardsSummary?.potentialRewards > MIN_POTENTIAL_REWARDS_FOR_MODIFY && (
                  <PotentialRewards
                    eligibleUnstakedAmount={rewardsSummary?.eligibleUnstakedAmount}
                    potentialRewards={rewardsSummary?.potentialRewards}
                  />
                )}
                <Row className="action-buttons">
                  <Col>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="btn-modify"
                      onClick={modifyHandler}
                      disabled={modifyDisabled}
                    >
                      <img
                        src={Pen}
                        alt="Modify icon"
                      />
                      <span>Modify</span>
                      {modifying && <div className="spinner btn-spinner"></div>}
                    </Button>

                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="btn-renew"
                      onClick={autoRenewHandler}
                    >
                      <img
                        src={AutoRenew}
                        alt="Auto Renew icon"
                      />
                      <span>
                        Auto renew{' '}
                        <span className="auto-renew-state">
                          ({rewardsSummary?.autoRenew ? 'on' : 'off'})
                        </span>
                      </span>
                    </Button>

                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="btn-cancel"
                      onClick={cancelPlanHandler}
                    >
                      <img
                        src={Bin}
                        alt="Cancel icon"
                      />
                      <span>Cancel</span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {rewardsSummary && (
        <History
          payments={rewardsSummary.payments}
          balanceHistory={rewardsSummary.balanceHistory}
        />
      )}
      <Confirm
        title="Confirm cancelation"
        question="Are you sure you want to cancel your plan?<br />You will lose all rewards earned to date!"
        acceptText="Proceed"
        rejectText="Cancel"
        closeHandler={confirmCloseHandler}
        acceptHandler={confirmAcceptHandler}
        rejectHandler={confirmRejectHandler}
        show={showConfirm}
      />
      <Modify
        closeHandler={modifyCloseHandler}
        acceptHandler={modifyAcceptHandler}
        rejectHandler={modifyRejectHandler}
        show={showModify}
        planSetup={modifyPlanSetup}
      />
    </>
  );
};

export default Summary;
