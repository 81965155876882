import { Card } from 'react-bootstrap';

const CardWidget = (props) => {
  return (
    <Card>
      <Card.Img
        variant="top"
        src={props.icon}
      />
      <Card.Body>
        <Card.Title className="text-white-bold">
          <span>{props.title}</span>
          <span>{props.title2}</span>
        </Card.Title>
        <Card.Text>
          <span>{props.message1}</span>
          <span>{props.message2}</span>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CardWidget;
