import { useRef, useState, useEffect } from "react";

import { Modal, Button, Form } from "react-bootstrap";

import Slider from "rc-slider";

import Calendar from "../../svg/Calendar.svg";
import PieChart from "../../svg/PieChart.svg";

const MAX_STAKING_AMOUNT = +process.env.REACT_APP_MAX_STAKING_AMOUNT;
const MIN_MODIFY_AMOUNT =
  +process.env.REACT_APP_MIN_POTENTIAL_REWARDS_FOR_MODIFY;

const Modify = ({
  closeHandler,
  acceptHandler,
  rejectHandler,
  show,
  planSetup,
}) => {
  if (planSetup.balanceBpm > MAX_STAKING_AMOUNT) {
    planSetup.difference = MAX_STAKING_AMOUNT - planSetup.lockedBpm;
  }
  const sliderDefault =
    40000000 < planSetup.difference ? 20000000 : planSetup.difference / 2;

  // Refs
  const amountRef = useRef();
  const periodRef = useRef();

  // State
  const [planAmount, setPlanAmount] = useState();
  const [planAmountPercentage, setPlanAmountPercentage] =
    useState(sliderDefault);
  const [modifying, setModifying] = useState(false);

  // Handlers
  const handleClose = () => {
    closeHandler();
  };
  const handleReject = () => {
    rejectHandler();
  };
  const handleAccept = () => {
    setModifying(true);
    const amount = Number(parseFloat(planAmount).toFixed(2));
    acceptHandler(amount, +periodRef.current.value);
    setModifying(false);
  };
  const amountChangeHandler = (val) => {
    setPlanAmount(val);
    setPlanAmountPercentage(+val);
  };

  useEffect(() => {
    let isMounted = true;

    if (!planSetup) return;

    if (isMounted) {
      setPlanAmount(sliderDefault);
      setPlanAmountPercentage(sliderDefault);
    }

    return () => {
      isMounted = false;
    };
  }, [planSetup, sliderDefault]);

  return (
    <Modal show={show} size="lg" centered className="modify-modal">
      <Modal.Header>
        <Modal.Title>Modify plan</Modal.Title>
        <Button onClick={handleClose} className="btn-close circle"></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="icon">
          <img src={PieChart} alt="Amount icon" />
        </div>

        <div className="how-much-bpm">
          {planSetup.difference > 0 ? (
            <>
              <p className="title">
                HOW MUCH ADDITIONAL BPM DO YOU WANT TO LOCK-UP?
              </p>
              <Slider
                defaultValue={sliderDefault}
                onChange={amountChangeHandler}
                ref={amountRef}
                min={0}
                max={
                  planSetup.difference > 20000000
                    ? 20000000
                    : planSetup.difference
                }
                value={planAmountPercentage}
              />
              <div className="slider-explanation">
                {planAmount &&
                  Number(parseFloat(planAmount)).toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                BPM is{" "}
                {(
                  (planAmountPercentage / planSetup.difference) *
                  100
                ).toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                % of my{" "}
                {planSetup.difference.toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                available holdings.
              </div>
            </>
          ) : (
            <p className="title">YOU HAVE NO UNALLOCATED BPM TO LOCK-UP</p>
          )}
        </div>

        <div className="how-many-months">
          <div className="icon">
            <img src={Calendar} alt="Period icon" />
          </div>
          <p className="title">
            {planSetup.months.length > 1
              ? "INCREASE LOCK-UP PERIOD?"
              : "LOCK-UP PERIOD"}
          </p>

          <div className="period-selector-container">
            <Form.Select
              aria-label="Period selector"
              ref={periodRef}
              defaultValue="12"
              className="period-selector"
            >
              {planSetup.months.map((period) => (
                <option value={period} key={period}>
                  {period}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleReject} size="sm">
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleAccept}
          size="sm"
          disabled={planAmount < MIN_MODIFY_AMOUNT || modifying}
        >
          Modify{modifying && <div className="spinner btn-spinner"></div>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Modify;
