import { useRef, useState, useEffect, useContext, useMemo } from 'react';

import jwt_decode from 'jwt-decode';

import AuthContext from '../context/AuthProvider';
import AppContext from '../context/AppProvider';

import { Modal, Container, Row, Col, Button } from 'react-bootstrap';

import axios from '../api/axios';
import TextInput from './TextInput';
import Warning from './../svg/Warning.svg';

const LOGIN_URL = '/account/login';

const Login = () => {
  const {
    setUser,
    setToken,
    persist,
    setPersist,
    setRoles,
    setWallets,
    setRevellers,
    setCurrentWallet,
  } = useContext(AuthContext);
  const { showLogin, setShowLogin, setShowRegister, setShowForgot } = useContext(AppContext);

  const emailRef = useRef();
  const errorRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [signingIn, setSigningIn] = useState(false);

  const handleShowRegister = () => {
    setShowRegister(true);
    resetForm();
    setShowLogin(false);
  };

  const handleLoginClose = () => {
    resetForm();
    setShowLogin(false);
  };

  const resetForm = () => {
    setEmail('');
    setPassword('');
  };

  const handleForgottenPassword = () => {
    setShowLogin(false);
    setShowForgot(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePersist = () => {
    setPersist((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    localStorage.setItem('persist', persist);
  }, [persist]);

  useEffect(() => {
    if (showLogin) emailRef.current.focus();
  }, [showLogin]);

  useEffect(() => {
    setErrorMessage('');
  }, [email, password]);

  const formComplete = useMemo(() => {
    return email !== '' && password !== '';
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Disable sign in button until this try block has finished
      setSigningIn(true);

      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ EmailAddress: email, Password: password }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );

      // Get access token and decode roles to store in Auth context
      const accessToken = response?.data;
      const decoded = accessToken ? jwt_decode(accessToken) : undefined;
      const userData = JSON.parse(decoded?.UserData || null);
      const roles = userData?.roles || [];
      const user = userData
        ? {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.emailAddress,
          }
        : null;
      const wallets = userData?.walletAddresses || [];
      const revellers = userData?.revellers || [];

      setUser(user);
      setToken(accessToken);
      setRoles(roles);
      setWallets(wallets);
      setRevellers(revellers);
      setCurrentWallet(wallets.find((wallet) => wallet.default === true)?.id);

      // Reset the form state
      resetForm();

      // Close the popup
      setShowLogin(false);
    } catch (error) {
      if (error?.message === 'canceled') return;
      if (!error?.response) {
        setErrorMessage('No server response');
      } else if (error.response?.status === 400) {
        setErrorMessage('Sign in provided malformed or missing credentials');
      } else if (error.response?.status === 401) {
        setErrorMessage('Sign in failed for provided credentials');
      } else if (error.response?.status === 423) {
        setErrorMessage('Account is locked, please contact the administrator');
      } else if (error.response?.status === 428) {
        setErrorMessage('Verify your email address before logging in');
      } else {
        setErrorMessage('Login failed for unknown reason');
      }

      errorRef.current.focus();
    } finally {
      // Re-enable teh sign in button
      setSigningIn(false);
    }
  };

  return (
    <>
      <Modal
        show={showLogin}
        size="lg"
        fullscreen="md-down"
      >
        <Modal.Header>
          <Button
            onClick={handleLoginClose}
            className="btn-close circle"
          ></Button>
        </Modal.Header>
        <Modal.Body className="sign-in-modal">
          <section>
            <div className="modal-title">Welcome back!</div>
            <div className="modal-instructions">Sign in to continue</div>

            {errorMessage !== '' && (
              <Container className="">
                <Row
                  className="error-message mb-4 me-0 ms-0"
                  aria-live="assertive"
                  ref={errorRef}
                >
                  <Col className="flex-grow-0 align-self-center">
                    <img
                      src={Warning}
                      alt="Warning"
                    />
                  </Col>
                  <Col className="flex-grow-1 align-self-center">{errorMessage}</Col>
                </Row>
              </Container>
            )}

            <form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  <Col
                    xs={12}
                    lg={6}
                    className="mb-4"
                  >
                    <TextInput
                      id="email"
                      type="text"
                      inputRef={emailRef}
                      value={email}
                      setValue={handleEmailChange}
                      label="Email Address"
                      autoComplete="email"
                    />
                  </Col>
                  <Col
                    xs={12}
                    lg={6}
                    className="mb-4"
                  >
                    <TextInput
                      id="password"
                      type="password"
                      value={password}
                      setValue={handlePasswordChange}
                      label="Password"
                      autoComplete="current-password"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center mb-4 mt-2 mt-md-4 mb-md-5">
                    <Button
                      variant="primary"
                      className="modal-primary-cta"
                      type="submit"
                      disabled={signingIn || !formComplete}
                    >
                      Sign in{signingIn && <div className="spinner btn-spinner"></div>}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <div className="persist-sign-in">
                      <input
                        type="checkbox"
                        id="persist"
                        onChange={togglePersist}
                        checked={persist}
                      />
                      <label htmlFor="persist">
                        <span className="d-none d-sm-inline">Trust this device&nbsp;&nbsp;(</span>
                        Stay signed in for 7 days
                        <span className="d-none d-sm-inline">)</span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="modal-links">
                    <div>
                      <span className="me-2">
                        <span className="d-sm-none">No account?</span>
                        <span className="d-none d-sm-inline">Haven't got an account?</span>
                      </span>
                      <span
                        className="link"
                        onClick={handleShowRegister}
                      >
                        Register
                      </span>
                    </div>
                    <div>
                      <span
                        className="link"
                        onClick={handleForgottenPassword}
                      >
                        Forgot password?
                      </span>
                    </div>
                  </Col>
                </Row>
              </Container>
            </form>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
