import { Card } from 'react-bootstrap';

const SocialCard = (props) => {
  return (
    <Card>
      <Card.Header>
        <Card.Img
          variant="top"
          src={props.icon}
        />
      </Card.Header>
      <Card.Body>
        <Card.Title className="text-white-bold">
          <div className="title1">{props.title1}</div>
          <div className="title2">{props.title2}</div>
        </Card.Title>
        <Card.Text className="message">{props.message}</Card.Text>
      </Card.Body>
      <Card.Footer>
        <Card.Link
          href={props.link}
          target="_blank"
          className="btn btn-outline-primary btn-sm"
        >
          {props.linkText}
        </Card.Link>
      </Card.Footer>
    </Card>
  );
};

export default SocialCard;
