const Strap = ({ head1, head2, text }) => {
  return (
    <div className="plan-text">
      <h3>
        <span className="text-white-outline">{head1}</span>{' '}
        <span className="text-white-bold">{head2}</span>
      </h3>
      <p>{text}</p>
    </div>
  );
};

export default Strap;
