import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);
  const [roles, setRoles] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [revellers, setRevellers] = useState([]);
  const [currentWallet, setCurrentWallet] = useState();

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        persist,
        setPersist,
        roles,
        setRoles,
        wallets,
        setWallets,
        revellers,
        setRevellers,
        currentWallet,
        setCurrentWallet,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
