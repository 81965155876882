import { useContext } from 'react';
import jwt_decode from 'jwt-decode';

import axios from '../api/axios';
import AuthContext from '../context/AuthProvider';
import useError from './useError';

const useRefreshToken = () => {
  const errorHandler = useError();
  const { setUser, setToken, setRoles, setWallets, setRevellers, setCurrentWallet } =
    useContext(AuthContext);

  const refresh = async () => {
    try {
      const response = await axios.get('/account/RefreshToken', {
        withCredentials: true,
      });

      const accessToken = response?.data;
      const decoded = accessToken ? jwt_decode(accessToken) : undefined;
      const userData = JSON.parse(decoded?.UserData || null);
      const roles = userData?.roles || [];
      const user = userData
        ? {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.emailAddress,
          }
        : null;
      const wallets = userData?.walletAddresses || [];
      const revellers = userData?.revellers || [];

      setUser(user);
      setToken(accessToken);
      setRoles(roles);
      setWallets(wallets);
      setRevellers(revellers);
      setCurrentWallet(wallets.find((wallet) => wallet.default === true)?.id);

      return response.data;
    } catch (error) {
      errorHandler(error);
      localStorage.removeItem('persist');
    }
  };
  return refresh;
};

export default useRefreshToken;
