import { Container, Row, Col } from 'react-bootstrap';

const Admin = () => {
  return (
    <>
      <section className="hero">
        <Container className="home-hero">
          <Row>
            <Col>
              <h1 className="text-white-outline">Admin</h1>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Admin;
