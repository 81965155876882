import { useContext } from 'react';
import AppContext from '../../context/AppProvider';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Revel = () => {
  const { setShowConnectWallet } = useContext(AppContext);

  const connectWalletHandler = async () => {
    setShowConnectWallet(true);
  };

  return (
    <Container className="revel-container">
      <Row>
        <Col>
          <img
            srcSet="/images/revel@xs.webp 230w, /images/revel@xs.png 230w, /images/revel@md.webp 340w, /images/revel@md.png 340w, /images/revel.webp 446w, /images/revel.png 446w"
            sizes="(max-width: 375px) 230px, (max-width: 767px) 340px, 446px"
            src="/images/revel.png"
            alt="Let's get Revelling graphic"
          ></img>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>
            <span className="text-white-outline">Let's get</span>{' '}
            <span className="text-white-bold">Revelling!</span>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="supporting-text">
            In order to access the full rewards hub and start claiming rewards, you must connect
            your wallet.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="btn-connect">
          <Button onClick={connectWalletHandler}>Connect your wallet</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Revel;
