import { useEffect, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const Alias = ({
  title,
  alias,
  acceptText,
  rejectText,
  closeHandler,
  acceptHandler,
  rejectHandler,
  show,
}) => {
  const aliasRef = useRef();

  const [aliasInput, setAliasInput] = useState(alias === undefined || alias === null ? '' : alias);

  const aliasInputHandler = (e) => {
    setAliasInput(e.target.value);
  };

  const handleClose = () => {
    closeHandler();
  };
  const handleReject = () => {
    rejectHandler();
  };
  const handleAccept = () => {
    acceptHandler(aliasInput);
  };

  useEffect(() => {
    if (show) {
      aliasRef.current.focus();
    }
  }, [show]);

  useEffect(() => {
    setAliasInput(alias === undefined || alias === null ? '' : alias);
  }, [alias]);

  return (
    <>
      <Modal
        className="alias-modal"
        show={show}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Button
            onClick={handleClose}
            className="btn-close circle"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <input
            ref={aliasRef}
            className="input"
            type="text"
            max="50"
            placeholder="Use something that represents your wallet, such as 'long term bag' or 'for the kids'"
            value={aliasInput}
            onChange={aliasInputHandler}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleReject}
            size="sm"
          >
            {rejectText}
          </Button>
          <Button
            variant="primary"
            onClick={handleAccept}
            size="sm"
          >
            {acceptText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Alias;
