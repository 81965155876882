import { Routes, Route } from 'react-router-dom';

import Layout from './pages/Layout';
import Home from './pages/Home';
import Support from './pages/Support';
import EarlyBird from './pages/EarlyBird';
import Rewards from './pages/Rewards';
import Account from './pages/Account';
import Missing from './pages/Missing';
import Admin from './pages/Admin';
import Unauthorized from './pages/Unauthorized';

import PersistLogin from './components/PersistLogin';
import RequireAuth from './components/RequireAuth';

const ROLES = {
  Rewards: 5,
  Admin: 4,
};

function App() {
  return (
    <Routes>
      <Route element={<Layout ROLES={ROLES} />}>
        <Route element={<PersistLogin />}>
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/support"
            element={<Support />}
          />
          <Route
            path="/unauthorized"
            element={<Unauthorized />}
          />

          {/* protected */}
          <Route element={<RequireAuth allowedRoles={[ROLES.Rewards]} />}>
            <Route
              path="/earlybird"
              element={<EarlyBird />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Rewards]} />}>
            <Route
              path="/rewards"
              element={<Rewards />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Rewards]} />}>
            <Route
              path="/account"
              element={<Account />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route
              path="/admin"
              element={<Admin />}
            />
          </Route>
        </Route>
      </Route>

      {/* catch all */}
      <Route
        path="*"
        element={<Missing />}
      />
    </Routes>
  );
}

export default App;
