import { useRef, useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import AuthContext from '../context/AuthProvider';
import AppContext from '../context/AppProvider';
import useError from '../hooks/useError';
import useLogout from '../hooks/useLogout';

import { Container, Row, Col, Button } from 'react-bootstrap';

import { v4 as uuidv4 } from 'uuid';
import ToastType from '../enums/toastType';

import Confirm from '../components/Confirm';
import Alias from '../components/Alias';
import DeleteAccount from '../components/DeleteAccount';
import EditableTextInput from '../components/EditableTextInput';

import Bin from '../svg/Bin.svg';
import Pen from '../svg/Pen.svg';
import Star from '../svg/Star.svg';
import StarFilled from '../svg/StarFilled.svg';
import TextInput from '../components/TextInput';

const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"@#£$%^&*\-=+_()<>?|{}~;:]).{8,32}$/;

const FIELD_TYPE = {
  firstName: 1,
  lastName: 2,
  email: 3,
  password: 4,
};

const Account = () => {
  const axiosPrivate = useAxiosPrivate();
  const errorHandler = useError();
  const logout = useLogout();
  const navigate = useNavigate();

  const {
    user,
    setUser,
    wallets,
    setWallets,
    currentWallet,
    setCurrentWallet,
    revellers,
    setRevellers,
  } = useContext(AuthContext);
  const { setToast, setShowConnectWallet } = useContext(AppContext);

  const [showConfirm, setShowConfirm] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showAlias, setShowAlias] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  // State
  const [walletDisconnectId, setWalletDisconnectId] = useState();
  const [walletAlias, setWalletAlias] = useState();
  const [walletAliasId, setWalletAliasId] = useState();

  // Password Helpers
  const isValidPassword = (val) => {
    return PASSWORD_REGEX.test(val);
  };
  const passwordsMatch = (password, password2) => {
    return password === password2;
  };

  // Password state
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const passwordRef = useRef();
  const [passwordIsTouched, setPasswordIsTouched] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordTested, setPasswordTested] = useState(false);
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const [password2, setPassword2] = useState('');
  const [password2IsTouched, setPassword2IsTouched] = useState(false);
  const [password2IsValid, setPassword2IsValid] = useState(false);
  const [password2Tested, setPassword2Tested] = useState(false);
  const [password2ValidationMessage, setPassword2ValidationMessage] = useState('');
  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
    setPasswordIsTouched(true);
    if (passwordTested) {
      testPassword(e.target.value);
    }
  };
  const passwordBlurHandler = (e) => {
    if (!passwordIsTouched) return;
    testPassword(e.target.value);
  };
  const testPassword = (val) => {
    if (isValidPassword(val)) {
      setPasswordIsValid(true);
      setPasswordValidationMessage('');
    } else {
      setPasswordIsValid(false);
      setPasswordValidationMessage(
        'Password must be a mix of lower/upper numeric and special characters'
      );
    }
    setPasswordTested(true);
    if (isValidPassword(val) && password2IsTouched) {
      testPassword2(password2);
    }
  };
  const password2ChangeHandler = (e) => {
    setPassword2(e.target.value);
    setPassword2IsTouched(true);
    if (password2Tested || (passwordIsValid && e.target.value === password)) {
      testPassword2(e.target.value);
    }
  };
  const password2BlurHandler = (e) => {
    if (!password2IsTouched) return;
    testPassword2(e.target.value);
  };
  const testPassword2 = (val) => {
    if (passwordsMatch(password, val)) {
      setPassword2IsValid(true);
      setPassword2ValidationMessage('');
    } else {
      setPassword2IsValid(false);
      setPassword2ValidationMessage('Passwords must match');
    }
    setPassword2Tested(true);
  };
  const currentPasswordChangeHandler = (e) => {
    setCurrentPassword(e.target.value);
  };

  // Refs
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();

  //Handlers
  const firstNameSaveHandler = () => {
    saveUserData(FIELD_TYPE.firstName);
  };
  const lastNameSaveHandler = () => {
    saveUserData(FIELD_TYPE.lastName);
  };
  const emailSaveHandler = () => {
    saveUserData(FIELD_TYPE.email);
  };
  const saveUserData = async (type) => {
    const controller = new AbortController();
    try {
      let payload;
      switch (type) {
        case FIELD_TYPE.firstName:
          const firstName = firstNameRef.current.value;
          if (firstName === user.firstName) return;
          payload = JSON.stringify({ firstName: firstName });
          await axiosPrivate.put('/account/updateuser', payload, { signal: controller.signal });
          setUser((prev) => ({ ...prev, firstName }));
          setToast((prev) => {
            return [
              ...prev,
              { id: uuidv4(), type: ToastType.success, message: 'First name updated' },
            ];
          });
          break;
        case FIELD_TYPE.lastName:
          const lastName = lastNameRef.current.value;
          if (lastName === user.lastName) return;
          payload = JSON.stringify({ lastName: lastName });
          await axiosPrivate.put('/account/updateuser', payload, { signal: controller.signal });
          setUser((prev) => ({ ...prev, lastName }));
          setToast((prev) => {
            return [
              ...prev,
              { id: uuidv4(), type: ToastType.success, message: 'Last name updated' },
            ];
          });
          break;
        case FIELD_TYPE.email:
          const email = emailRef.current.value;
          if (email === user.email) return;
          payload = JSON.stringify({ emailAddress: email });
          await axiosPrivate.put('/account/updateuser', payload, { signal: controller.signal });
          setUser((prev) => ({ ...prev, email }));
          setToast((prev) => {
            return [
              ...prev,
              {
                id: uuidv4(),
                type: ToastType.success,
                delay: 15000,
                title: 'Success',
                message:
                  'Email updated, you are advised to logout now and login with your new credentials. If you do not do this, you may encounter errors.',
              },
            ];
          });
          break;
        case FIELD_TYPE.password:
          payload = JSON.stringify({ password: currentPassword, newPassword: password });
          await axiosPrivate.put('/account/updateuser', payload, { signal: controller.signal });
          setToast((prev) => {
            return [
              ...prev,
              {
                id: uuidv4(),
                type: ToastType.success,
                delay: 15000,
                title: 'Success',
                message:
                  'Password updated, you are advised to logout now and login with your new credentials. If you do not do this, you may encounter errors.',
              },
            ];
          });
          setShowPasswordConfirm(false);
          setShowPasswordReset(false);
          cancelPasswordHandler();
          break;
        default:
          setToast((prev) => {
            return [
              ...prev,
              { id: uuidv4(), type: ToastType.error, message: 'Change not saved due to an error' },
            ];
          });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
    }
  };

  const connectWalletHandler = async () => {
    setShowConnectWallet(true);
  };

  const removeWalletHandler = async (id) => {
    setWalletDisconnectId(id);
    setShowConfirm(true);
  };

  const updateAliasHandler = async (wallet) => {
    setWalletAlias(wallet.friendlyName);
    setWalletAliasId(wallet.id);
    setShowAlias(true);
  };

  const setDefaultHandler = async (id) => {
    if (wallets.find((wallet) => wallet.id === id).default === true) return;

    const controller = new AbortController();
    try {
      await axiosPrivate.get(`/wallet/setDefaultWallet?walletAddress=${id}`, {
        signal: controller.signal,
      });

      setWallets((prev) =>
        prev.map((wallet) => {
          if (wallet.id === id) {
            wallet.default = true;
          } else {
            wallet.default = false;
          }
          return wallet;
        })
      );

      setCurrentWallet(wallets.find((wallet) => wallet.id === id)?.id);

      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.success,
            title: 'Success',
            message: `Your default wallet has been updated`,
          },
        ];
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
    }
  };

  const confirmCloseHandler = () => {
    setShowConfirm(false);
  };

  const confirmAcceptHandler = async () => {
    if (walletDisconnectId === null) {
      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.error,
            title: 'Error',
            message: 'Something went wrong, please try again later.',
          },
        ];
      });
    }

    const controller = new AbortController();
    try {
      await axiosPrivate.get(`/wallet/removeWallet?walletAddress=${walletDisconnectId}`, {
        signal: controller.signal,
      });

      const response = await axiosPrivate.get(`/account/getuser`, {
        signal: controller.signal,
      });

      const wallets = response.data?.walletAddresses || [];
      const revellers = response.data?.revellers || [];
      setWallets(wallets);
      setRevellers(revellers);
      setCurrentWallet(wallets.find((wallet) => wallet.default === true)?.id);

      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.success,
            title: 'Success',
            message: `Your wallet has been disconnected`,
          },
        ];
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
      setShowConfirm(false);
      setWalletDisconnectId();
    }
  };

  const confirmRejectHandler = () => {
    setShowConfirm(false);
  };

  const aliasCloseHandler = () => {
    setWalletAlias();
    setWalletAliasId();
    setShowAlias(false);
  };

  const aliasRejectHandler = () => {
    setWalletAlias();
    setWalletAliasId();
    setShowAlias(false);
  };

  const aliasAcceptHandler = async (alias) => {
    const controller = new AbortController();

    try {
      await axiosPrivate.post(
        `/wallet/updateWalletAlias?walletAddress=${walletAliasId}`,
        {
          friendlyName: alias,
        },
        {
          signal: controller.signal,
        }
      );

      setWallets((prev) => {
        const t = prev.map((wallet) => {
          if (wallet.id === walletAliasId) {
            return { ...wallet, friendlyName: alias };
          }
          return wallet;
        });

        return t;
      });

      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.success,
            title: 'Success',
            message: `Alias has been added`,
          },
        ];
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
    }

    setWalletAlias();
    setWalletAliasId();
    setShowAlias(false);
  };

  const changePasswordHandler = () => {
    setShowPasswordReset(true);
  };

  const updatePasswordHandler = () => {
    setShowPasswordConfirm(true);
  };

  const confirmPasswordCloseHandler = () => {
    setShowPasswordConfirm(false);
  };

  const confirmPasswordAcceptHandler = async () => {
    saveUserData(FIELD_TYPE.password);
  };

  const confirmPasswordRejectHandler = () => {
    setShowPasswordConfirm(false);
  };

  const cancelPasswordHandler = () => {
    setShowPasswordReset(false);
    setCurrentPassword('');
    setPassword('');
    setPasswordIsTouched(false);
    setPasswordIsValid(false);
    setPasswordTested(false);
    setPasswordValidationMessage('');
    setPassword2('');
    setPassword2IsTouched(false);
    setPassword2IsValid(false);
    setPassword2Tested(false);
    setPassword2ValidationMessage('');
  };

  const deleteAccountHandler = () => {
    setShowDeleteAccount(true);
  };

  const deleteCloseHandler = () => {
    setShowDeleteAccount(false);
  };

  const deleteRejectHandler = () => {
    setShowDeleteAccount(false);
  };

  const deleteAcceptHandler = async () => {
    const controller = new AbortController();

    try {
      await axiosPrivate.delete(`/account/delete`, {
        signal: controller.signal,
      });

      window.scrollTo(0, 0);
      await logout();
      navigate('/');
      setToast((prev) => {
        return [
          ...prev,
          {
            id: uuidv4(),
            type: ToastType.success,
            delay: 15000,
            title: 'Success',
            message: `Your account has been permanently deleted. We are sorry to see you go.`,
          },
        ];
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
    }
  };

  const passwordComplete = useMemo(() => {
    return isValidPassword(password) && passwordsMatch(password, password2);
  }, [password, password2]);

  return (
    <>
      <section className="hero-container account-hero-container">
        <Container className="hero secondary-hero">
          <Row className="hero-msg">
            <Col>
              <h1 className="text-white-bold">Account</h1>
            </Col>
            <Col>
              <p>View and manage your account information.</p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="account-container">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="edit-panel personal-details">
                <h1>Personal details</h1>
                <EditableTextInput
                  id="firstName"
                  type="text"
                  label="First name"
                  inputRef={firstNameRef}
                  disabled={true}
                  inputSaveHandler={firstNameSaveHandler}
                  initialValue={user.firstName}
                />
                <EditableTextInput
                  id="lastName"
                  type="text"
                  label="Last name"
                  inputRef={lastNameRef}
                  disabled={true}
                  inputSaveHandler={lastNameSaveHandler}
                  initialValue={user.lastName}
                />
                <EditableTextInput
                  id="email"
                  type="text"
                  label="Email"
                  inputRef={emailRef}
                  disabled={true}
                  inputSaveHandler={emailSaveHandler}
                  initialValue={user.email}
                />
              </div>
            </Col>
            <Col lg={6}>
              {!showPasswordReset ? (
                <div className="edit-panel password-management">
                  <h1>Password management</h1>

                  <div className="message">
                    <p>Reset your password here.</p>
                    <p>We recommend you do this periodically.</p>
                  </div>

                  <div className="buttons">
                    <Button
                      size="sm"
                      onClick={changePasswordHandler}
                    >
                      Change password
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="edit-panel password-reset">
                  <h1>Password management</h1>

                  <div className="password-inputs">
                    {passwordIsTouched && !passwordIsValid && passwordValidationMessage && (
                      <div className="error-messages">
                        <p>{passwordValidationMessage}</p>
                      </div>
                    )}
                    {passwordIsValid &&
                      password2IsTouched &&
                      !password2IsValid &&
                      password2ValidationMessage && (
                        <div className="error-messages">
                          <p>{password2ValidationMessage}</p>
                        </div>
                      )}

                    <TextInput
                      id="currentPassword"
                      type="password"
                      setValue={currentPasswordChangeHandler}
                      label="Current password"
                      autoComplete="old-password"
                    />

                    <TextInput
                      id="password"
                      type="password"
                      value={password}
                      setValue={passwordChangeHandler}
                      label="New password"
                      inputRef={passwordRef}
                      isValid={passwordIsValid}
                      isTouched={passwordIsTouched}
                      isTested={passwordTested}
                      onBlur={passwordBlurHandler}
                      autoComplete="new-password"
                    />

                    <TextInput
                      id="password2"
                      type="password"
                      value={password2}
                      setValue={password2ChangeHandler}
                      label="Confirm your new password"
                      isValid={password2IsValid}
                      isTouched={password2IsTouched}
                      isTested={password2Tested}
                      onBlur={password2BlurHandler}
                      autoComplete="new-password"
                    />
                  </div>

                  <div className="buttons">
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={updatePasswordHandler}
                      disabled={!currentPassword?.length > 8 || !passwordComplete}
                    >
                      Update
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={cancelPasswordHandler}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </Col>
            <Col lg={6}>
              <div className="edit-panel wallet-management">
                <div>
                  <h1>Wallet management</h1>
                </div>
                {wallets?.length > 0 ? (
                  <div className="wallets">
                    {wallets.map((wallet) => (
                      <div
                        key={wallet.id}
                        className="wallet"
                      >
                        <span className="text-start flex-grow-1">
                          {wallet.friendlyName ? (
                            <span className="alias">{wallet.friendlyName}</span>
                          ) : (
                            <>
                              <span className="raddress d-sm-none">
                                {wallet.id.substring(0, 1)}.....{wallet.id.slice(-6)}
                              </span>
                              <span className="raddress d-none d-sm-inline-block d-xxl-none">
                                {wallet.id.substring(0, 8)}.....{wallet.id.slice(-8)}
                              </span>
                              <span className="raddress d-none d-xxl-inline-block">
                                {wallet.id}
                              </span>
                            </>
                          )}
                          {wallet.default && (
                            <span className="default d-none d-sm-inline-block">(Default)</span>
                          )}
                        </span>
                        <span className="text-end">
                          <span className="icon-default">
                            <img
                              onClick={() => setDefaultHandler(wallet.id)}
                              src={wallet.default ? StarFilled : Star}
                              alt="Default icon"
                            />
                          </span>
                          <span className="icon-edit">
                            <img
                              onClick={() => updateAliasHandler(wallet)}
                              src={Pen}
                              alt="Edit icon"
                            />
                          </span>
                          <span className="icon-delete">
                            <img
                              onClick={() => removeWalletHandler(wallet.id)}
                              src={Bin}
                              alt="Delete icon"
                            />
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="no-data">You have not connected a wallet to your account yet.</p>
                )}
                <div className="buttons">
                  <Button
                    size="sm"
                    onClick={connectWalletHandler}
                  >
                    Connect wallet
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="edit-panel reveller-management">
                <div>
                  <h1>Your Revellers</h1>
                </div>
                {revellers?.length > 0 ? (
                  <div className="revellers">
                    {revellers.map((reveller) => (
                      <div
                        key={reveller.id}
                        className="reveller"
                      >
                        {reveller.name}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="no-data">
                    You do not have any Revellers associated with your account.
                  </p>
                )}
                <div className="buttons">
                  <a
                    className="btn btn-primary btn-sm"
                    href="https://nft.onxrp.com/launchpad/details/revellers/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mint a Reveller
                  </a>
                </div>
              </div>
            </Col>
            <Col>
              <div className="edit-panel danger-zone">
                <h1>Deactivation and deletion</h1>
                <div className="message">
                  <p>We will be sorry to see you go.</p>
                  <p>
                    If you decide to permanently remove your account (including all of your personal
                    data), this is where you do it!
                  </p>
                </div>
                <div className="buttons">
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={deleteAccountHandler}
                  >
                    Permanently delete my account
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Confirm
        title="Confirm wallet disconnection"
        question="Are you sure you want to disconnect your wallet?"
        acceptText="Proceed"
        rejectText="Cancel"
        closeHandler={confirmCloseHandler}
        acceptHandler={confirmAcceptHandler}
        rejectHandler={confirmRejectHandler}
        show={showConfirm}
      />
      <Confirm
        title="Confirm password change"
        question="Are you sure you want change your password?"
        acceptText="Proceed"
        rejectText="Cancel"
        closeHandler={confirmPasswordCloseHandler}
        acceptHandler={confirmPasswordAcceptHandler}
        rejectHandler={confirmPasswordRejectHandler}
        show={showPasswordConfirm}
      />
      <Alias
        title="Set an alias"
        alias={walletAlias}
        acceptText="Save"
        rejectText="Cancel"
        closeHandler={aliasCloseHandler}
        acceptHandler={aliasAcceptHandler}
        rejectHandler={aliasRejectHandler}
        show={showAlias}
      />
      <DeleteAccount
        closeHandler={deleteCloseHandler}
        acceptHandler={deleteAcceptHandler}
        rejectHandler={deleteRejectHandler}
        show={showDeleteAccount}
      />
    </>
  );
};

export default Account;
