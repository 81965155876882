import { useContext, useState, useEffect } from 'react';
import AuthContext from '../context/AuthProvider';
import AppContext from '../context/AppProvider';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useError from '../hooks/useError';

import { v4 as uuidv4 } from 'uuid';
import ToastType from '../enums/toastType';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import SocialCard from '../components/SocialCard';
import Faqs from '../components/Faqs';

import Twitter from '../svg/Twitter.svg';
import Telegram from '../svg/Telegram.svg';
import Discord from '../svg/Discord.svg';
import Instagram from '../svg/Instagram.svg';

const Support = () => {
  const axiosPrivate = useAxiosPrivate();
  const errorHandler = useError();
  const { setToast } = useContext(AppContext);

  const { wallets } = useContext(AuthContext);

  const [selectedWallet, setSelectedWallet] = useState();
  const [question, setQuestion] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [faqs, setFaqs] = useState([]);

  const walletChangeHandler = (e) => {
    setSelectedWallet(wallets.find((wallet) => wallet.id === e.target.value)?.id);
  };

  const questionChangeHandler = (e) => {
    setQuestion(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const controller = new AbortController();

    try {
      setSubmitting(true);

      if (!question) {
        setToast((prev) => {
          return [
            ...prev,
            {
              id: uuidv4(),
              type: ToastType.error,
              title: 'Sorry',
              message: 'You must ask a question.',
            },
          ];
        });
        return;
      }

      await axiosPrivate.post(
        `/support/RewardsQuestion`,
        { question: question, relatedWallet: selectedWallet },
        {
          signal: controller.signal,
        }
      );

      setSubmitted(true);
    } catch (error) {
      errorHandler(error);
    } finally {
      controller.abort();
      setSubmitting(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getFaq = async () => {
      try {
        const response = await axiosPrivate.get(`support/faq`, {
          signal: controller.signal,
        });
        isMounted && setFaqs(response.data ? response.data : []);
      } catch (error) {}
    };

    getFaq();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate]);

  return (
    <>
      <section className="hero-container support-hero-container">
        <Container className="hero secondary-hero">
          <Row className="hero-msg">
            <Col>
              <h1 className="text-white-bold">Support</h1>
            </Col>
            <Col>
              <p>
                If you can’t find the answer in our FAQ, be sure to open a support ticket in our
                Discord where our team are always on hand to help.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="faq-container">
        <Container>
          <Row>
            <Col>
              <Faqs faqs={faqs} />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="social-container">
        <Container>
          <Row className="social-banner">
            <Col>
              <h1>We’re on social media too!</h1>
              <p>
                Join our active communities on Twitter, Telegram, Discord, & Instagram. Don’t forget
                to turn on notifications to be the first in the know!
              </p>
            </Col>
          </Row>
          <Row className="social-cards" style={{justifyContent:'center'}}>
            <Col
              xs={12}
              md={6}
              xxl={3}
            >
              <SocialCard
                icon={Twitter}
                title1="Twitter"
                title2="@BPMWallet"
                message="Follow the official BPM Wallet account on Twitter for instant news and project updates."
                linkText="Follow us on Twitter"
                link="https://twitter.com/bpmwallet"
              />
            </Col>
            <Col
              xs={12}
              md={6}
              xxl={3}
            >
              <SocialCard
                icon={Telegram}
                title1="Telegram"
                title2="BPM Wallet"
                message="Join our brilliant Telegram community for project updates and support."
                linkText="Follow us on Telegram"
                link="https://t.me/BPMWallet"
              />
            </Col>
            <Col
              xs={12}
              md={6}
              xxl={3}
            >
              <SocialCard
                icon={Discord}
                title1="Discord"
                title2="BPM"
                message="Become part of our awesome, growing community by joining our Discord server."
                linkText="Follow us on Discord"
                link="https://discord.com/invite/SPzGu6Dsfn"
              />
            </Col>
            {/* <Col
              xs={12}
              md={6}
              xxl={3}
            >
              <SocialCard
                icon={Instagram}
                title1="Instagram"
                title2="BPM"
                message="Follow us on Instagram and check out our BPM gallery, stories and more."
                linkText="Follow us on Instagram"
                link="https://www.instagram.com/bpmwallet/"
              />
            </Col> */}
          </Row>
        </Container>
      </section>

      <section className="help-container">
        <Container>
          <Row>
            <Col>
              {submitted ? (
                <>
                  <h1>Thank you!</h1>
                  <p>We have received your question and will reply soon.</p>
                </>
              ) : (
                <>
                  <h1>Still need help?</h1>
                  <p>
                    If you can’t find your answer on our FAQ page, please complete the below contact
                    form and a member of the team will be in touch ASAP.
                  </p>
                  <form
                    className="help-form"
                    onSubmit={submitHandler}
                  >
                    <div className="form-field-text">
                      <div className="input-container">
                        <label className="input-label">Question relates to</label>
                        <Form.Select
                          className="input-select"
                          aria-label="Wallet selector"
                          onChange={walletChangeHandler}
                          defaultValue={null}
                        >
                          <option>My question doesn't relate to a specific wallet</option>
                          {wallets.map((wallet) => (
                            <option
                              value={wallet.id}
                              key={wallet.id}
                            >
                              {wallet.friendlyName ? wallet.friendlyName : wallet.id}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="form-field-text">
                      <div className="input-container">
                        <label
                          htmlFor="helpQuestion"
                          className="input-label"
                        >
                          Question
                        </label>
                        <textarea
                          id="helpQuestion"
                          className="textarea-input"
                          placeholder="e.g. I am writing to..."
                          onChange={questionChangeHandler}
                        ></textarea>
                      </div>
                    </div>
                    <div className="buttons">
                      <Button
                        type="submit"
                        disabled={submitting}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Support;
