const Callout = (props) => {
  return (
    <>
      <h3 className="title text-white-bold">{props.title}</h3>
      <p className="strap1">{props.strap1}</p>
      <p className="strap2">{props.strap2}</p>
    </>
  );
};

export default Callout;
