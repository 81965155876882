import { useContext } from 'react';
import AuthContext from '../context/AuthProvider';
import AppContext from '../context/AppProvider';
import { Link } from 'react-router-dom';

import { Container, Row, Col, Button } from 'react-bootstrap';

import Callout from '../components/Callout';
import CardWidget from '../components/CardWidget';
import Banner from '../components/Banner';

import XummWhite from '../svg/XummWhite.svg';
import Increase from '../svg/Increase.svg';
import Tiers from '../svg/Tiers.svg';
import Cup from '../svg/Cup.svg';
import Starred from '../svg/Starred.svg';
import Switch from '../svg/Switch.svg';
import Crown from '../svg/Crown.svg';

const Home = () => {
  const { token } = useContext(AuthContext);
  const { setShowLogin, setShowRegister, setShowRewardPlans } = useContext(AppContext);

  const handleSignIn = () => {
    setShowLogin(true);
  };

  const handleRegister = () => {
    setShowRegister(true);
  };

  const handleRewardPlans = () => {
    setShowRewardPlans(true);
  };

  return (
    <>
      <section className="hero-container home-hero-container">
        <Container className="hero">
          <Row className="hero-msg">
            <Col>
              <h1 className="text-white-outline">Welcome to</h1>
            </Col>
            <Col>
              <h2 className="text-white-bold">BPM Rewards</h2>
            </Col>
            <Col>
              <p className="strap1">Connect your Xumm wallet to lock up your $BPM and earn airdrop rewards.</p>
              <p className="strap2"></p>
            </Col>
          </Row>
          {token ? (
            <Row className="hero-cta">
              <Col md={6}>
                <Link
                  className="btn btn-primary"
                  to="/rewards"
                >
                  Lock up now
                </Link>
              </Col>
              <Col md={6}>
                <a
                  className="btn btn-outline-primary"
                  href="https://nft.onxrp.com/launchpad/details/revellers/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mint a Reveller
                </a>
              </Col>
            </Row>
          ) : (
            <Row className="hero-cta">
              <Col md={6}>
                <Button
                  onClick={handleSignIn}
                  variant="primary"
                >
                  Sign in
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  onClick={handleRegister}
                  variant="outline-primary"
                  className="mt-3 mt-md-0"
                >
                  Register
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </section>

      <section className="callout">
        <Container>
          <Row>
            <Col>
              {token ? (
                <Callout
                  title="Next level lock-ups"
                  strap1="Airdrops delivered through the lock-up platform are sourced from a reserved 20%"
                  strap2="of the token supply and holders remain in full custody of their tokens."
                />
              ) : (
                <Callout
                  title="Next level lock-ups"
                  strap1="You remain in full custody of your tokens, we track your balance through daily snapshots."
                  strap2="Airdropped rewards delivered through the lock up platform are sourced from a reserved 20% of the token supply."
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col></Col>
          </Row>
        </Container>
      </section>

      <section className="cards">
        <Container>
          {token ? (
            <Row>
              <Col
                xs={12}
                md={6}
                xl={3}
              >
                <CardWidget
                  icon={XummWhite}
                  title="Powered by"
                  title2="XUMM Wallet"
                  message1="Always remain in full custody of your $BPM tokens. Simply connect your XUMM Wallet, create a lock up plan and reap the rewards."
                />
              </Col>
              <Col
                xs={12}
                md={6}
                xl={3}
              >
                <CardWidget
                  icon={Increase}
                  title="Maximise airdrops"
                  title2="with a Reveller"
                  message1="Combine your $BPM tokens and Reveller NFT to optimise your airdrops."
                />
              </Col>
              <Col
                xs={12}
                md={6}
                xl={3}
              >
                <CardWidget
                  icon={Starred}
                  title="Track your $BPM"
                  title2="rewards daily"
                  message1="Real-time data analytics allows you to track your progress. "
                />
              </Col>
              <Col
                xs={12}
                md={6}
                xl={3}
              >
                <CardWidget
                  icon={Switch}
                  title="Your rewards,"
                  title2="your way"
                  message1="Tailor your plan to suit your end goal. To maximise airdrops, combine 1M+ $BPM with a platinum Reveller, and lock up for 12-months. Minimum lock up is 1,000 $BPM."
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col
                xs={12}
                md={6}
                xl={3}
              >
                <CardWidget
                  icon={XummWhite}
                  title="Powered by"
                  title2="XUMM Wallet"
                  message1="Our rewards platform allows you to remain in full custody of your $BPM tokens. Simply
          connect your XUMM wallet and reap the rewards."
                />
              </Col>
              <Col
                xs={12}
                md={6}
                xl={3}
              >
                <CardWidget
                  icon={Increase}
                  title="Maximise airdrops"
                  title2="with a Reveller"
                  message1="Combine your $BPM tokens and Reveller NFT to optimise your airdrops."
                />
              </Col>
              <Col
                xs={12}
                md={6}
                xl={3}
              >
                <CardWidget
                  icon={Tiers}
                  title="Build your own"
                  title2="rewards plan"
                  message1="Choose how much $BPM you want to lock up and for how long, your highest trait Reveller will auto-calculate your airdrop amount."
                />
              </Col>
              <Col
                xs={12}
                md={6}
                xl={3}
              >
                <CardWidget
                  icon={Cup}
                  title="Exclusive Reveller"
                  title2="holder rewards"
                  message1="Your Reveller NFT will earn you up to 4 raffle entries into our monthly prize draws. This feature is coming soon to your BPM rewards dashboard."
                />
              </Col>
            </Row>
          )}
        </Container>
      </section>

      <section className="banner">
        <Container>
          <Row>
            <Col>
              <Banner
                icon={Crown}
                title="Get ready to revel"
                message1a="Revellers are the genesis NFT collection from BPM,"
                message1b="joining the blockchain to reward holders,"
                message2a="enhance the event experience, and bridge the gap"
                message2b="between fans and their heroes."
                buttonTitle="Discover Revellers"
                buttonLink="https://bpmwallet.io/reveller-nfts"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Home;
