import { Container, Row, Col } from 'react-bootstrap';

const EarlyBird = () => {
  return (
    <section className="hero-container earlybird-hero-container">
      <Container className="hero secondary-hero">
        <Row className="hero-msg">
          <Col>
            <h1 className="text-white-bold">Early Bird</h1>
          </Col>
          <Col>
            <p>The early bird airdrop programme was completed in June 2023.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default EarlyBird;
