import { useState } from 'react';

import Tick from './../svg/Tick.svg';
import Alert from './../svg/Alert.svg';
import Eye from './../svg/Eye.svg';
import EyeHide from './../svg/EyeHide.svg';

const TextInput = (props) => {
  const {
    id,
    type,
    inputRef,
    value,
    setValue,
    label,
    isValid,
    isTouched,
    invalidMessage,
    onBlur,
    isTested,
    showSpinner,
    disabled,
    locked,
    autoComplete,
  } = props;

  const [inputType, setInputType] = useState(type);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const passwordToggleHandler = () => {
    if (type === 'password') {
      setPasswordVisible((prev) => {
        return !prev;
      });
      if (inputType === 'password') {
        setInputType('text');
      } else {
        setInputType('password');
      }
    }
  };

  return (
    <>
      <div
        className={`form-field-text ${isTouched && isTested && !isValid ? 'invalid-input' : ''} ${
          locked ? 'hide-border' : ''
        }`}
      >
        <div className="input-container">
          <label
            htmlFor={id}
            className="input-label"
          >
            {label}
          </label>
          <input
            type={inputType}
            id={id}
            ref={inputRef}
            autoComplete={autoComplete ? autoComplete : 'off'}
            value={value}
            onChange={setValue}
            onBlur={onBlur}
            className="text-input"
            disabled={disabled || false}
          />
        </div>
        {showSpinner && (
          <div className="icon-container">
            <div className="spinner input-spinner"></div>
          </div>
        )}
        {isTouched && isTested && (
          <div className="icon-container">
            <img
              src={isValid ? Tick : Alert}
              alt={isValid ? 'Valid input' : 'Invalid input'}
            />
          </div>
        )}
        {type === 'password' && (
          <div
            className="icon-container"
            onClick={passwordToggleHandler}
          >
            <img
              src={passwordVisible ? Eye : EyeHide}
              alt="Password toggle"
            />
          </div>
        )}
      </div>
      {isTouched && isTested && !isValid && <div className="input-error">{invalidMessage}</div>}
    </>
  );
};

export default TextInput;
