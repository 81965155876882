import { useContext, useMemo } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import useLogout from '../hooks/useLogout';
import AuthContext from '../context/AuthProvider';
import AppContext from '../context/AppProvider';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import Logo from './Logo';

const Menu = ({ ROLES }) => {
  const { token, roles, wallets } = useContext(AuthContext);

  const location = useLocation();
  const currentPage = location.pathname || '/';

  const { showLogin, setShowLogin } = useContext(AppContext);

  const isEarlyBird = useMemo(() => {
    if (wallets === undefined || wallets === null) {
      return false;
    }
    return wallets.find((wallet) => wallet.isEarlyBird);
  }, [wallets]);

  const signInHandler = () => {
    setShowLogin(true);
  };

  const navigate = useNavigate();
  const logout = useLogout();
  const signOutHandler = async () => {
    await logout();
    navigate('/');
  };

  const expand = 'lg';
  return (
    <>
      <Navbar
        key={expand}
        expand={expand}
        sticky={'top'}
        collapseOnSelect={true}
      >
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <Logo />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${expand}`}
            className="circle"
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <Logo />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1">
                <Nav.Link
                  as={Link}
                  to="/"
                  className={currentPage === '/' && !showLogin ? 'active' : ''}
                  eventKey="0"
                >
                  Home
                </Nav.Link>
                {token && roles?.find((role) => role === ROLES.Rewards) && (
                  <>
                    {isEarlyBird && (
                      <Nav.Link
                        as={Link}
                        to="/earlybird"
                        className={currentPage === '/earlybird' && !showLogin ? 'active' : ''}
                        eventKey="2"
                      >
                        Early Bird
                      </Nav.Link>
                    )}
                    <Nav.Link
                      as={Link}
                      to="/rewards"
                      className={currentPage === '/rewards' && !showLogin ? 'active' : ''}
                      eventKey="3"
                    >
                      Lock ups
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/account"
                      className={currentPage === '/account' && !showLogin ? 'active' : ''}
                      eventKey="4"
                    >
                      Account
                    </Nav.Link>
                  </>
                )}
                {token && roles?.find((role) => role === ROLES.Admin) && (
                  <Nav.Link
                    as={Link}
                    to="/admin"
                    className={currentPage === '/admin' && !showLogin ? 'active' : ''}
                    eventKey="5"
                  >
                    Admin
                  </Nav.Link>
                )}
                <Nav.Link
                  as={Link}
                  to="/support"
                  className={currentPage === '/support' && !showLogin ? 'active' : ''}
                  eventKey="1"
                >
                  Support
                </Nav.Link>

                {token ? (
                  <Nav.Link
                    className="sign-out-container"
                    onClick={signOutHandler}
                    eventKey="6"
                  >
                    <div className="sign-out">Sign Out</div>
                  </Nav.Link>
                ) : (
                  <Nav.Link
                    active={showLogin}
                    className="sign-in-container"
                    onClick={signInHandler}
                    eventKey="7"
                  >
                    Sign In
                  </Nav.Link>
                )}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Menu;
