import { useRef, useState, useEffect, useContext } from 'react';

import AppContext from '../context/AppProvider';
import axios from '../api/axios';

import { Modal, Container, Row, Col, Button } from 'react-bootstrap';

import TextInput from './TextInput';
import Warning from './../svg/Warning.svg';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Login = () => {
  const { showForgot, setShowForgot, setShowRegister, setShowLogin } = useContext(AppContext);

  const emailRef = useRef();
  const errorRef = useRef();

  // Reset Success
  const [resetComplete, setResetComplete] = useState(false);

  // State
  const [email, setEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailIsTouched, setEmailIsTouched] = useState(false);
  const [emailTested, setEmailTested] = useState(false);
  const handleEmailChange = (e) => {
    setEmailIsTouched(true);
    setEmail(e.target.value);
    if (emailTested) {
      setEmailIsValid(EMAIL_REGEX.test(e.target.value));
    }
  };
  const handleEmailBlur = (e) => {
    if (!emailIsTouched) return;
    setEmailIsValid(EMAIL_REGEX.test(e.target.value));
    setEmailTested(true);
  };

  // Changing modals
  const handleShowRegister = () => {
    setShowForgot(false);
    clearForm();
    setResetComplete(false);
    setShowRegister(true);
  };
  const handleShowLogin = () => {
    setShowForgot(false);
    clearForm();
    setResetComplete(false);
    setShowLogin(true);
  };
  const handleForgotClose = () => {
    clearForm();
    setResetComplete(false);
    setShowForgot(false);
  };
  const clearForm = () => {
    setEmail('');
    setEmailIsValid(false);
    setEmailIsTouched(false);
    setEmailTested(false);
  };

  useEffect(() => {
    if (showForgot) emailRef.current.focus();
  }, [showForgot]);

  useEffect(() => {
    setErrorMessage('');
  }, [email]);

  const [errorMessage, setErrorMessage] = useState('');
  const [resetting, setResetting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Disable button until this try block has finished
      setResetting(true);

      await axios.post(
        '/account/resetPasswordRequest',
        { EmailAddress: email },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );

      // Reset the form state
      clearForm();

      setResetComplete(true);
    } catch (error) {
      if (error?.message === 'canceled') return;
      if (!error?.response) {
        setErrorMessage('No server response');
      } else if (error.response?.status === 400) {
        setErrorMessage('Missing email address');
      } else {
        setErrorMessage('Unknown error');
      }

      errorRef.current.focus();
    } finally {
      // Re-enable the reset button
      setResetting(false);
    }
  };

  return (
    <>
      <Modal
        show={showForgot}
        size="lg"
        fullscreen="md-down"
      >
        <Modal.Header>
          <Button
            onClick={handleForgotClose}
            className="btn-close circle"
          ></Button>
        </Modal.Header>
        <Modal.Body className="sign-in-modal">
          {resetComplete ? (
            <section>
              <div className="modal-title">Check your email!</div>
              <div className="modal-instructions">
                If your email address is registered, you'll receive an email shortly
              </div>
            </section>
          ) : (
            <section>
              <div className="modal-title">Reset your password</div>
              <div className="modal-instructions">Enter your email address</div>

              {errorMessage !== '' && (
                <Container className="">
                  <Row
                    className="error-message mb-4 me-0 ms-0"
                    aria-live="assertive"
                    ref={errorRef}
                  >
                    <Col className="flex-grow-0 align-self-center">
                      <img
                        src={Warning}
                        alt="Warning"
                      />
                    </Col>
                    <Col className="flex-grow-1 align-self-center">{errorMessage}</Col>
                  </Row>
                </Container>
              )}

              <form onSubmit={handleSubmit}>
                <Container>
                  <Row>
                    <Col className="mb-4">
                      <TextInput
                        id="email"
                        type="text"
                        inputRef={emailRef}
                        value={email}
                        setValue={handleEmailChange}
                        label="Email Address"
                        isValid={emailIsValid}
                        isTouched={emailIsTouched}
                        isTested={emailTested}
                        invalidMessage="Invalid email address"
                        onBlur={handleEmailBlur}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-center mb-3">
                      <Button
                        variant="primary"
                        className="modal-primary-cta"
                        type="submit"
                        disabled={resetting || !emailIsValid}
                      >
                        Reset{resetting && <div className="spinner btn-spinner"></div>}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="modal-links">
                      <div>
                        <span className="me-2">
                          <span className="d-sm-none">Back to</span>
                          <span className="d-none d-sm-inline">Back to</span>
                        </span>
                        <span
                          className="link"
                          onClick={handleShowLogin}
                        >
                          Sign in
                        </span>
                      </div>
                      <div>
                        <span className="me-2">
                          <span className="d-sm-none">No account?</span>
                          <span className="d-none d-sm-inline">Haven't got an account?</span>
                        </span>
                        <span
                          className="link"
                          onClick={handleShowRegister}
                        >
                          Register
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </form>
            </section>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
