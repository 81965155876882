import { useContext } from 'react';
import AppContext from '../context/AppProvider';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';

import PlatinumIcon from '../svg/PlatinumIcon.svg';
import GoldIcon from '../svg/GoldIcon.svg';
import SilverIcon from '../svg/SilverIcon.svg';
import BronzeIcon from '../svg/BronzeIcon.svg';

const RewardPlans = () => {
  const { showRewardPlans, setShowRewardPlans } = useContext(AppContext);

  const handleClose = () => {
    setShowRewardPlans(false);
  };

  const plat3mt1 = '15.12%';
  const plat3mt2 = '21.6%';
  const plat3mt3 = '27%';
  const plat3mt4 = '30%';
  const plat6mt1 = '42.33%';
  const plat6mt2 = '60.48%';
  const plat6mt3 = '75.6%';
  const plat6mt4 = '84%';
  const plat12mt1 = '151.2%';
  const plat12mt2 = '216%';
  const plat12mt3 = '270%';
  const plat12mt4 = '300%';

  const gold3mt1 = '12.1%';
  const gold3mt2 = '17.28%';
  const gold3mt3 = '21.6%';
  const gold3mt4 = '24%';
  const gold6mt1 = '33.88%';
  const gold6mt2 = '48.38%';
  const gold6mt3 = '60.48%';
  const gold6mt4 = '67.2%';
  const gold12mt1 = '121%';
  const gold12mt2 = '172.8%';
  const gold12mt3 = '216%';
  const gold12mt4 = '240%';

  const silver3mt1 = '9.07%';
  const silver3mt2 = '12.96%';
  const silver3mt3 = '16.2%';
  const silver3mt4 = '18%';
  const silver6mt1 = '25.34%';
  const silver6mt2 = '36.28%';
  const silver6mt3 = '45.36%';
  const silver6mt4 = '50.4%';
  const silver12mt1 = '90.7%';
  const silver12mt2 = '129.6%';
  const silver12mt3 = '162%';
  const silver12mt4 = '180%';

  const bronze3mt1 = '6.05%';
  const bronze3mt2 = '8.64%';
  const bronze3mt3 = '10.8%';
  const bronze3mt4 = '12%';
  const bronze6mt1 = '16.94%';
  const bronze6mt2 = '24.19%';
  const bronze6mt3 = '30.24%';
  const bronze6mt4 = '33.6%';
  const bronze12mt1 = '60.5%';
  const bronze12mt2 = '86.4%';
  const bronze12mt3 = '108%';
  const bronze12mt4 = '120%';

  const bpm3mt1 = '2.27%';
  const bpm3mt2 = '3.24%';
  const bpm3mt3 = '4.05%';
  const bpm3mt4 = '4.5%';
  const bpm6mt1 = '6.36%';
  const bpm6mt2 = '9.07%';
  const bpm6mt3 = '11.34%';
  const bpm6mt4 = '12.6%';
  const bpm12mt1 = '22.7%';
  const bpm12mt2 = '32.4%';
  const bpm12mt3 = '40.5%';
  const bpm12mt4 = '45%';

  return (
    <>
      <Modal
        show={showRewardPlans}
        size="xl"
        centered
        className="rewards-plan-modal"
        fullscreen="sm-down"
      >
        <Modal.Header>
          <Button
            onClick={handleClose}
            className="btn-close circle"
          ></Button>
        </Modal.Header>
        <Modal.Body className="confirm-modal">
          <Container>
            <Row>
              <Col>
                <h1 className="text-white-bold modal-title">Reward plans</h1>
                <p className="modal-instructions">
                  The higher tier Reveller you own the better the rewards, further enhanced by the
                  amount of $BPM held and the longer the lock-up period.
                </p>
              </Col>
            </Row>
            <section className="rewards-grid">
              <Row className="icon">
                <Col>
                  <img
                    src={PlatinumIcon}
                    alt="Platinum icon"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="name platinum-text">Platinum</Col>
              </Row>
              <Row>
                <Col className="period"></Col>
                <Col className="period">
                  <span className="d-none d-sm-block">3 MONTHS</span>
                  <span className="d-sm-none">3 M</span>
                </Col>
                <Col className="period">
                  <span className="d-none d-sm-block">6 MONTHS</span>
                  <span className="d-sm-none">6 M</span>
                </Col>
                <Col className="period">
                  <span className="d-none d-sm-block">12 MONTHS</span>
                  <span className="d-sm-none">12 M</span>
                </Col>
              </Row>
              <Row>
                <Col className="tier">1K+</Col>
                <Col className="platinum-text perc">{plat3mt1}</Col>
                <Col className="platinum-text perc">{plat6mt1}</Col>
                <Col className="platinum-text perc">{plat12mt1}</Col>
              </Row>
              <Row>
                <Col className="tier">100K+</Col>
                <Col className="platinum-text perc">{plat3mt2}</Col>
                <Col className="platinum-text perc">{plat6mt2}</Col>
                <Col className="platinum-text perc">{plat12mt2}</Col>
              </Row>
              <Row>
                <Col className="tier">500K+</Col>
                <Col className="platinum-text perc">{plat3mt3}</Col>
                <Col className="platinum-text perc">{plat6mt3}</Col>
                <Col className="platinum-text perc">{plat12mt3}</Col>
              </Row>
              <Row>
                <Col className="tier">1M+</Col>
                <Col className="platinum-text perc">{plat3mt4}</Col>
                <Col className="platinum-text perc">{plat6mt4}</Col>
                <Col className="platinum-text perc">{plat12mt4}</Col>
              </Row>

              <Row className="icon">
                <Col>
                  <img
                    src={GoldIcon}
                    alt="Gold icon"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="name gold-text">Gold</Col>
              </Row>
              <Row>
                <Col className="period"></Col>
                <Col className="period">
                  <span className="d-none d-sm-block">3 MONTHS</span>
                  <span className="d-sm-none">3 M</span>
                </Col>
                <Col className="period">
                  <span className="d-none d-sm-block">6 MONTHS</span>
                  <span className="d-sm-none">6 M</span>
                </Col>
                <Col className="period">
                  <span className="d-none d-sm-block">12 MONTHS</span>
                  <span className="d-sm-none">12 M</span>
                </Col>
              </Row>
              <Row>
                <Col className="tier">1K+</Col>
                <Col className="gold-text perc">{gold3mt1}</Col>
                <Col className="gold-text perc">{gold6mt1}</Col>
                <Col className="gold-text perc">{gold12mt1}</Col>
              </Row>
              <Row>
                <Col className="tier">100K+</Col>
                <Col className="gold-text perc">{gold3mt2}</Col>
                <Col className="gold-text perc">{gold6mt2}</Col>
                <Col className="gold-text perc">{gold12mt2}</Col>
              </Row>
              <Row>
                <Col className="tier">500K+</Col>
                <Col className="gold-text perc">{gold3mt3}</Col>
                <Col className="gold-text perc">{gold6mt3}</Col>
                <Col className="gold-text perc">{gold12mt3}</Col>
              </Row>
              <Row>
                <Col className="tier">1M+</Col>
                <Col className="gold-text perc">{gold3mt4}</Col>
                <Col className="gold-text perc">{gold6mt4}</Col>
                <Col className="gold-text perc">{gold12mt4}</Col>
              </Row>

              <Row className="icon">
                <Col>
                  <img
                    src={SilverIcon}
                    alt="Silver icon"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="name silver-text">Silver</Col>
              </Row>
              <Row>
                <Col className="period"></Col>
                <Col className="period">
                  <span className="d-none d-sm-block">3 MONTHS</span>
                  <span className="d-sm-none">3 M</span>
                </Col>
                <Col className="period">
                  <span className="d-none d-sm-block">6 MONTHS</span>
                  <span className="d-sm-none">6 M</span>
                </Col>
                <Col className="period">
                  <span className="d-none d-sm-block">12 MONTHS</span>
                  <span className="d-sm-none">12 M</span>
                </Col>
              </Row>
              <Row>
                <Col className="tier">1K+</Col>
                <Col className="silver-text perc">{silver3mt1}</Col>
                <Col className="silver-text perc">{silver6mt1}</Col>
                <Col className="silver-text perc">{silver12mt1}</Col>
              </Row>
              <Row>
                <Col className="tier">100K+</Col>
                <Col className="silver-text perc">{silver3mt2}</Col>
                <Col className="silver-text perc">{silver6mt2}</Col>
                <Col className="silver-text perc">{silver12mt2}</Col>
              </Row>
              <Row>
                <Col className="tier">500K+</Col>
                <Col className="silver-text perc">{silver3mt3}</Col>
                <Col className="silver-text perc">{silver6mt3}</Col>
                <Col className="silver-text perc">{silver12mt3}</Col>
              </Row>
              <Row>
                <Col className="tier">1M+</Col>
                <Col className="silver-text perc">{silver3mt4}</Col>
                <Col className="silver-text perc">{silver6mt4}</Col>
                <Col className="silver-text perc">{silver12mt4}</Col>
              </Row>

              <Row className="icon">
                <Col>
                  <img
                    src={BronzeIcon}
                    alt="Bronze icon"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="name bronze-text">Bronze</Col>
              </Row>
              <Row>
                <Col className="period"></Col>
                <Col className="period">
                  <span className="d-none d-sm-block">3 MONTHS</span>
                  <span className="d-sm-none">3 M</span>
                </Col>
                <Col className="period">
                  <span className="d-none d-sm-block">6 MONTHS</span>
                  <span className="d-sm-none">6 M</span>
                </Col>
                <Col className="period">
                  <span className="d-none d-sm-block">12 MONTHS</span>
                  <span className="d-sm-none">12 M</span>
                </Col>
              </Row>
              <Row>
                <Col className="tier">1K+</Col>
                <Col className="bronze-text perc">{bronze3mt1}</Col>
                <Col className="bronze-text perc">{bronze6mt1}</Col>
                <Col className="bronze-text perc">{bronze12mt1}</Col>
              </Row>
              <Row>
                <Col className="tier">100K+</Col>
                <Col className="bronze-text perc">{bronze3mt2}</Col>
                <Col className="bronze-text perc">{bronze6mt2}</Col>
                <Col className="bronze-text perc">{bronze12mt2}</Col>
              </Row>
              <Row>
                <Col className="tier">500K+</Col>
                <Col className="bronze-text perc">{bronze3mt3}</Col>
                <Col className="bronze-text perc">{bronze6mt3}</Col>
                <Col className="bronze-text perc">{bronze12mt3}</Col>
              </Row>
              <Row>
                <Col className="tier">1M+</Col>
                <Col className="bronze-text perc">{bronze3mt4}</Col>
                <Col className="bronze-text perc">{bronze6mt4}</Col>
                <Col className="bronze-text perc">{bronze12mt4}</Col>
              </Row>

              <Row className="icon">
                <Col></Col>
              </Row>
              <Row>
                <Col className="name bpm-only-text">$BPM Only</Col>
              </Row>
              <Row>
                <Col className="period"></Col>
                <Col className="period">
                  <span className="d-none d-sm-block">3 MONTHS</span>
                  <span className="d-sm-none">3 M</span>
                </Col>
                <Col className="period">
                  <span className="d-none d-sm-block">6 MONTHS</span>
                  <span className="d-sm-none">6 M</span>
                </Col>
                <Col className="period">
                  <span className="d-none d-sm-block">12 MONTHS</span>
                  <span className="d-sm-none">12 M</span>
                </Col>
              </Row>
              <Row>
                <Col className="tier">1K+</Col>
                <Col className="bpm-only-text perc">{bpm3mt1}</Col>
                <Col className="bpm-only-text perc">{bpm6mt1}</Col>
                <Col className="bpm-only-text perc">{bpm12mt1}</Col>
              </Row>
              <Row>
                <Col className="tier">100K+</Col>
                <Col className="bpm-only-text perc">{bpm3mt2}</Col>
                <Col className="bpm-only-text perc">{bpm6mt2}</Col>
                <Col className="bpm-only-text perc">{bpm12mt2}</Col>
              </Row>
              <Row>
                <Col className="tier">500K+</Col>
                <Col className="bpm-only-text perc">{bpm3mt3}</Col>
                <Col className="bpm-only-text perc">{bpm6mt3}</Col>
                <Col className="bpm-only-text perc">{bpm12mt3}</Col>
              </Row>
              <Row>
                <Col className="tier">1M+</Col>
                <Col className="bpm-only-text perc">{bpm3mt4}</Col>
                <Col className="bpm-only-text perc">{bpm6mt4}</Col>
                <Col className="bpm-only-text perc">{bpm12mt4}</Col>
              </Row>
            </section>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RewardPlans;
