import { useContext } from 'react';

import { ToastContainer, Toast } from 'react-bootstrap';

import AppContext from '../context/AppProvider';
import ToastType from '../enums/toastType';

import Success from '../svg/Success.svg';
import Warning from '../svg/Warning.svg';
import Alert from '../svg/Alert.svg';

const Message = () => {
  const { toast, setToast } = useContext(AppContext);

  const clearToast = (uuid) => {
    setToast((prev) => {
      return prev.filter((msg) => msg.id !== uuid);
    });
  };

  return (
    <>
      <ToastContainer
        position="bottom-end"
        className="p-3"
      >
        {toast.map((t) => (
          <Toast
            key={t.id}
            onClose={() => clearToast(t.id)}
            delay={t.type !== ToastType.error ? (t.delay ? t.delay : 3000) : undefined}
            autohide={t.type !== ToastType.error ? true : false}
            bg={
              (t.type === ToastType.error && 'danger') ||
              (t.type === ToastType.warning && 'warning') ||
              (t.type === ToastType.success && 'success')
            }
            className={
              (t.type === ToastType.error && 'danger') ||
              (t.type === ToastType.warning && 'warning') ||
              (t.type === ToastType.success && 'success')
            }
          >
            <Toast.Header>
              <img
                src={
                  (t.type === ToastType.error && Alert) ||
                  (t.type === ToastType.warning && Warning) ||
                  (t.type === ToastType.success && Success)
                }
                alt=""
              />
              <div className="title">{t.title}</div>
            </Toast.Header>
            <Toast.Body>
              <div className="me-auto">{t.message}</div>
            </Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </>
  );
};

export default Message;
