import Accordion from 'react-bootstrap/Accordion';
import Faq from './Faq';

const Faqs = ({ faqs }) => {
  return (
    <>
      <Accordion className="faqs">
        {faqs.map((faq) => {
          return (
            <Faq
              faq={faq}
              key={faq.id}
            />
          );
        })}
      </Accordion>
    </>
  );
};

export default Faqs;
