import Accordion from 'react-bootstrap/Accordion';

const Faq = ({ faq }) => {
  return (
    <>
      <Accordion.Item eventKey={faq.id}>
        <Accordion.Header>{faq.question}</Accordion.Header>
        <Accordion.Body>{faq.answer}</Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default Faq;
